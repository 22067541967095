﻿import {
  DeclarationResultAustria,
  Responsibility,
  ResponsibilityAustria,
  Responsible,
  tryGetDeclarationAustria,
} from '../../../../api';
import { useProducers } from '../../../producers/Producers';
import { stringParam, useSearchParams } from '../../../../shared/hooks/useSearchParams';
import { Actions } from '../Actions';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteObject, useOutletContext } from 'react-router';
import { NotFoundInfo } from '../../../../shared/components/NotFoundInfo';
import { ReviewList } from './ReviewList';
import { expectNever, getPeriodShortName } from '../../../../helpers';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { useQueryValue } from '../../../../shared/hooks/useQueryValue';
import { useState } from 'react';
import { getPreviousPeriod } from '../../helpers';
import { PackagingKind, ResponsibilityKindNavItem } from './ResponsibilityKindNavItem';
import { aggregate } from './aggregate';
import { filter } from 'ix/iterable/operators';
import { getExtendedResponsibilities } from '../../de/useExtendedDeclaration';
import { getResponsibilitiesWithThirdPartyLicensing } from '../../de/getResponsibilitiesWithThirdPartyLicensing';

export const reviewRoutes: RouteObject[] = [
  { path: 'review', element: <Navigate to="./household" /> },
  { path: 'review/household', element: <ReviewStep kind="household" /> },
  { path: 'review/commercial', element: <ReviewStep kind="commercial" /> },
];

type ComparisonMode = 'LastPeriod' | 'LastYear';

export function ReviewStep({ kind }: { kind: 'household' | 'commercial' }) {
  const { t } = useTranslation('reports');

  const [search, setSearch] = useSearchParams('filter', stringParam<string>(''));
  const [year, period] = [useYearParam(), usePeriodParam()];
  const declaration = useOutletContext<DeclarationResultAustria | null>();
  const { year: prevYear, period: prevPeriod } = getPreviousPeriod(year, period);

  const [comparisonMode, setComparisonMode] = useState<ComparisonMode>('LastPeriod');

  const { year: comparisonYear, period: comparisonPeriod } =
    comparisonMode === 'LastPeriod' ? getPreviousPeriod(year, period) : { year: year - 1, period };

  const comparisonQuery = useTenantQuery(
    tryGetDeclarationAustria,
    {
      year: comparisonYear,
      period: comparisonPeriod,
    },
    false
  );

  const comparisonValue = useQueryValue(comparisonQuery);

  const comparisonResult =
    comparisonValue !== 'Running' && comparisonValue.Case !== 'Failed' ? comparisonValue.Fields[0] : null;

  const producers = useProducers();

  if (declaration === null) {
    return <NotFoundInfo />;
  }

  const comparisonResponsibilities: ResponsibilityAustria[] = comparisonResult?.responsibilities ?? [];

  // Aggregations
  const aggregations = aggregate([
    ...declaration.responsibilities,
    ...comparisonResponsibilities.map(r => ({ ...r, comparison: true })),
  ]);

  const lowerCasedSearch = search.toLocaleLowerCase();

  function stringContainsSearch(value: string | null) {
    if (lowerCasedSearch === '') {
      return true;
    }

    return value?.toLocaleLowerCase().includes(lowerCasedSearch) ?? false;
  }

  function containsSearch(value: Responsible | null) {
    if (value === null) {
      return false;
    }

    if (value === 'Customers') {
      return false;
    }

    if (value.Case === 'Producer') {
      if (stringContainsSearch(value.Fields[0])) {
        return true;
      }

      const producer = producers.accessible()[value.Fields[0]];
      if (producer) {
        return stringContainsSearch(producer.name) || stringContainsSearch(producer.lucidNumber);
      }

      return false;
    }

    if (value.Case === 'Supplier') {
      return stringContainsSearch(value.Fields[0]);
    }

    if (value.Case === 'Customer') {
      return stringContainsSearch(value.Fields[0]);
    }

    expectNever(value);
  }

  const filtered = aggregations.pipe(
    filter(a => {
      return lowerCasedSearch === '' || containsSearch(a.responsible);
    })
  );

  return (
    <div>
      <Actions year={year} period={period} declaration={declaration} />
      <div className="bg-light rounded mb-4">
        <InfoScreen icon="eye" color="primary" title={t('reviewStep.title')}>
          <p>{t('reviewStep.subtitle.at')}</p>
        </InfoScreen>
      </div>
      <ul className="nav nav-tabs">
        <li className="nav-item pe-2 pb-2 col-4">
          <input
            placeholder={t('global:search')}
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </li>
        <ResponsibilityKindNavItem kind="household" />
        <ResponsibilityKindNavItem kind="commercial" />
        <li className="nav-item ms-auto">
          <span className="me-2 text-secondary">{t('reviewStep.compareWith')}</span>
          <div className="btn-group" role="group">
            {prevPeriod !== period || prevYear !== year - 1 ? (
              <>
                <input
                  type="radio"
                  className="btn-check"
                  name="comparison-mode"
                  id="comparison-mode-year"
                  autoComplete="off"
                  onChange={() => setComparisonMode('LastYear')}
                  checked={comparisonMode == 'LastYear'}
                />
                <label className="btn btn-outline-secondary" htmlFor="comparison-mode-year">
                  {getPeriodShortName(period)} {year - 1}
                </label>
              </>
            ) : (
              <></>
            )}
            <input
              type="radio"
              className="btn-check"
              name="comparison-mode"
              id="comparison-mode-period"
              autoComplete="off"
              onChange={() => setComparisonMode('LastPeriod')}
              checked={comparisonMode == 'LastPeriod'}
            />
            <label className="btn btn-outline-secondary" htmlFor="comparison-mode-period">
              {getPeriodShortName(prevPeriod)} {prevYear}
            </label>
          </div>
        </li>
      </ul>
      <ReviewList kind={kind} data={filtered} />
    </div>
  );
}
