import { Responsible } from '../../../../api';
import { SystemForLicensing } from '../helpers';
import { SystemsOfCustomersInfo } from '../SystemsOfCustomersInfo';
import { SystemOfSupplierInfo } from '../SystemOfSupplierInfo';
import { SystemOfCustomerInfo } from '../SystemOfCustomerInfo';
import { SystemOfProducerInfo } from '../SystemOfProducerInfo';
import { expectNever } from '../../../../helpers';

export interface Props {
  producerId: string;
  licensing: Responsible;
  system: SystemForLicensing;
  count: number;
}

export function SystemForLicensingInfo({ producerId, system, licensing }: Props) {
  if (system.type === 'SystemsOfCustomers') {
    return <SystemsOfCustomersInfo />;
  }

  // System not defined for a supplier
  if (system.type === 'SystemOfSupplier') {
    return <SystemOfSupplierInfo producerId={producerId} supplierId={system.supplierId} allowUpdate={true} />;
  }

  // System not defined for a customer
  if (system.type === 'SystemOfCustomer') {
    return <SystemOfCustomerInfo producerId={producerId} customerId={system.customerId} allowUpdate={true} />;
  }

  // System not defined for a producer
  if (system.type === 'SystemOfProducer') {
    return <SystemOfProducerInfo producerId={system.producerId} allowUpdate={true} />;
  }

  // System not defined for a producer
  if (system.type === 'System') {
    if (typeof licensing === 'string' && licensing === 'Customers') {
      return <SystemsOfCustomersInfo />;
    }

    if (licensing.Case === 'Producer') {
      return <SystemOfProducerInfo producerId={licensing.Fields[0]} allowUpdate={true} />;
    }

    if (licensing.Case === 'Supplier') {
      return <SystemOfSupplierInfo producerId={producerId} supplierId={licensing.Fields[0]} allowUpdate={true} />;
    }

    if (licensing.Case === 'Customer') {
      return <SystemOfCustomerInfo producerId={producerId} customerId={licensing.Fields[0]} allowUpdate={true} />;
    }

    expectNever(licensing);
  }

  expectNever(system);
}
