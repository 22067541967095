{
  "navigation": {
    "notCreated": "not yet calculated",
    "calculationPending": "calculation pending",
    "inReview": "in review",
    "released": "released",
    "notReleased": "not yet released"
  },
  "support": {
    "hint": "Need help?"
  },
  "releaseDialog": {
    "notifyAboutRelease": "Notify responsible persons about release",
    "button": {
      "confirm": "Release",
      "cancel": "Cancel"
    }
  },
  "releaseButton": {
    "de": {
      "issueHint": "Not all issues have been fixed.<br />Do you want to release the placings nevertheless?",
      "hint": "Do you want to release the placings?",
      "release": "Release"
    },
    "at": {
      "issueHint": "Not all issues have been fixed.<br/ >Do you want to release the placings nevertheless?",
      "hint": "Do you want to release the placings?",
      "release": "Release"
    }
  },
  "csuReleaseButton": {
    "release": "Next"
  },
  "declarationLoader": {
    "loadingFailedHint": "Placements can't be displayed<br/> as they have not yet been released.",
    "notReleased": "Not Released"
  },
  "responsibleView": {
    "customers": "Customers",
    "supplier": "Supplier",
    "customer": "Customer"
  },
  "steps": {
    "de": {
      "import": "Import",
      "importAndCalculate": "Import and calculate",
      "thirdPartyLicensing": "Third party licensing",
      "calculate": "Calculate",
      "issues": "Issues",
      "review": "Review",
      "adjust": "Other placings",
      "report": "Report to LUCID",
      "license": "Report to system provider",
      "finish": "Finalize"
    },
    "at": {
      "import": "Import",
      "calculate": "Calculate",
      "issues": "Issues",
      "review": "Review",
      "adjust": "Other placings",
      "license": "Report to system provider",
      "finish": "Finalize"
    },
    "fr": {
      "importAndCalculate": "Import and calculate",
      "review": "Review",
      "finish": "Finalize"
    }
  },
  "systemInfo": {
    "unknownProviderHint": "Unknown system provider with ID {{id}}"
  },
  "systemOfProducerInfo": {
    "company": "Company",
    "noContract": "<strong>{{producer}}</strong> has no contracts associated with any system providers. Please add a contract.",
    "addContract": "Add",
    "editContract": "Edit"
  },
  "systemOfSupplierInfo": {
    "noThirdPartyLicensing": "No information has been shared by the <strong>supplier (ID: {{supplier}})</strong>.",
    "noThirdPartyLicensingWithName": "No information has been shared by the supplier <strong>{{supplierName}}</strong>.",
    "addThirdPartyLicensing": "Add",
    "editThirdPartyLicensing": "Edit"
  },
  "systemOfCustomerInfo": {
    "noThirdPartyLicensing": "No information has been shared by the <strong>customer (ID: {{customer}})</strong>.",
    "addThirdPartyLicensing": "Add",
    "editThirdPartyLicensing": "Edit"
  },
  "systemsOfCustomerInfo": {
    "unknownSystemProvider": "Unknown system providers of customers"
  },
  "withdrawButton": {
    "confirm": "Are you sure to withdraw these placings?<br />The placings will be lost and can only be recalculated based on the most recent data.",
    "csuConfirm": "Are you sure to withdraw the declaration?<br />The calculated cost will be lost. A new import will be required to recalculate your declaration.",
    "withdraw": "Withdraw"
  },
  "withdrawDialog": {
    "button": {
      "confirm": "Withdraw",
      "cancel": "Cancel"
    }
  },
  "availabilityView": {
    "heading": "Availability of data"
  },
  "createStep": {
    "pollingTitle": "Calculation pending...",
    "polling": "Placings are being calculated.<br/>This might take a moment.",
    "csuPolling": "Costs are being calculated.<br/>This might take a moment.",
    "noDataTitle": "No available data",
    "noData": "Placings can not be calculated\n because the data for this period is not complete.",
    "notReleasedTitle": "Not released",
    "notReleased": "Declarations can't be displayed because they have not been released yet.",
    "resultOk": "The declaration has been created."
  },
  "importButton": {
    "pendingLabel": "Processing",
    "label": "Upload Excel file"
  },
  "importErrors": {
    "headers": {
      "row": "Row",
      "description": "Description",
      "column": "Column"
    },
    "downloadButtonText": "Download a list of all errors (CSV)",
    "row": "Row",
    "inColumn": "in column",
    "Unexpected": "The imported file does not contain the expected content, please use our template.",
    "SheetNotFound": "The imported file does not contain the expected content, please use our template.",
    "Empty": "Not all required fields have been filled out.",
    "Row": {
      "FrUnexpectedDecimalValue": "Unexpected value for decimal field",
      "FrUnexpectedStringValue": "Unexpected value for text field",
      "FrUnexpectedIntValue": "Unexpected value for integer field",
      "FrUnexpectedBoolValue": "Unexpected value for yes/no field",
      "FrRecycledPetRequiresPercentage": "Missing percentage for recycled PET",
      "FrRecycledPetTraysRequiresPercentage": "Missing percentage for recycled PET from trays and pots",
      "FrRecycledFlexiblePeRequiresPercentage": "Missing percentage for recycled flexible PE",
      "FrRecycledRigidPeRequiresPercentage": "Missing percentage for recycled rigid PE",
      "FrRecycledRigidPpRequiresPercentage": "Missing percentage for recycled rigid PP",
      "FrRecycledRigidPsRequiresPercentage": "Missing percentage for recycled rigid PS",
      "FrRecycledPetExceedsTotal": "The amount of recycled PET exceeds the total amount of PET used in the packaging",
      "FrRecycledFlexiblePeHouseholdPercentageExceedsTotal": "The amount of recycled flexible PE from households exceeds the total amount of PE used in the packaging",
      "FrRecycledFlexiblePeExceedsTotal": "The amount of recycled flexible PE exceeds the total amount of PE used in the packaging",
      "FrRecycledRigidPeExceedsTotal": "The amount of recycled rigid PE exceeds the total amount of PE used in the packaging",
      "FrRecycledRigidPpExceedsTotal": "The amount of recycled rigid PP exceeds the total amount of PP used in the packaging",
      "FrRecycledRigidPsExceedsTotal": "The amount of recycled rigid PS exceeds the total amount of PS used in the packaging",
      "FrPackagingElementRequired": "At least one packaging unit is required",
      "FrSumOfWeightsIsZero": "The sum of the weights of all materials can't be 0",
      "FrSumOfWeightsExceedsMaximumPossibleWeight": "The sum of the declared weights is not compatible with the reported number of packaging units",
      "FrRecycledCardboardRequiresCardboard": "The bonus for recycled cardboard requires cardboard to be present in the packaging",
      "FrPenaltyRequiresPet": "The selected penalty requires a weight for PET",
      "FrPenaltyRequiresGlassAndPet": "The selected penalty requires a weight for glass and PET",
      "FrPenaltyRequiresCardboard": "The selected penalty requires a weight for cardboard",
      "FrPenaltyRequiresRigidPlastic": "The selected penalty requires a weight for rigid Plastic",
      "FrPenaltyRequiresGlassAndSteel": "The selected penalty requires a weight for glass and steel",
      "FrPenaltyRequiresPvc": "The selected penalty requires a weight for PVC",
      "FrPenaltyRequiresOpaquePet": "The selected penalty requires a weight for opaque PET",
      "FrPenaltyRequiresRigidPetAndAluPvcOrSilicone": "The selected penalty requires a weight for rigid PET and at least one of aluminium, PVC or silicone",
      "FrPenaltyRequiresGlass": "The selected penalty requires a weight for glass",
      "FrPenaltyRequiresGlassAndCeramics": "The selected penalty requires a weight for glass and ceramics",
      "FrPenaltyInvalid": "The selected penalty was not recognized",
      "FrPenaltyCategoryInvalid": "The selected penalty is not valid for the selected category",
      "FrAwarenessRaisingMeasureInvalid": "The selected bonus for raising of awareness was not recognized",
      "FrReductionMeasureInvalid": "The selected bonus for reduction measures was not recognized",
      "FrProductCodeInvalid": "The selected product code was not recognized",
      "FrReusedExceedsTotalQuantity": "The number of reused packaging exceeds the total quantity of UVC",
      "FrProductDescriptionRequired": "Missing product description",
      "FrProductCategoryCodeRequired": "Missing product category code",
      "FrCsuPutOnMarketRequired": "The number of CSUs put on market can't be 0",
      "FrPackagingElementAboveThresholdRequired": "At least one packaging unit above 0.1g is required",
      "FrPercentageOutOfRange": "Percentages for recycled material must be between 0% and 100%",
      "FrPenaltyRequiresPetPePp": "The selected penalty requires at least one weight for either PET, PE or PP",
      "FrRenewableResourcesInvalid": "The selected bonus for renewable resources was not recognized"
    }
  },
  "importStep": {
    "downloadText": "You can download an empty template file here:",
    "downloadFrench": "French language",
    "downloadEnglish": "English language",
    "noDataTitle": "No data available",
    "noData": "Please upload your Excel file.<br />The calculation will launch automatically once data is loaded.",
    "pendingTitle": "Pending",
    "pending": "Your data is being processed.",
    "successTitle": "Succeeded",
    "success": "Your data has been loaded successfully.<br/> Please proceed to step 2.",
    "failedTitle": "Import failed",
    "failed": "Your data has not been loaded successfully, because it contains errors.<br/> Please fix the issues and re-upload the file again.",
    "shortErrorListHint": "A total of {{numberOfErrors}} errors have been found.",
    "longErrorListHint": "Only the first {{limit}} of a total of {{numberOfErrors}} errors are shown."
  },
  "calculateStep": {
    "pollingTitle": "Calculation in progress...",
    "polling": "Your detailed declaration is being calculated.<br/>This might take a moment.",
    "failedTitle": "Calculation failed",
    "failed": "There was a problem preparing your declaration.<br/>Please review your files and reimport them in the import step.",
    "notReleasedTitle": "Not released",
    "notReleased": "The declaration can't be displayed because it has not been released yet.",
    "successTitle": "Calculation finished",
    "success": "Please proceed to step 3 and review your detailed declaration."
  },
  "notYetCreatedScreen": {
    "title": "Placings have not been calculated yet",
    "hint": "Placings can be calculated now. Please verify that the required data is present.",
    "calculate": "Calculate now"
  },
  "auditorExport": {
    "title": "Auditor Export",
    "subtitle": "All placings including those not subject to system participation, aggregated by",
    "responsibility": "Responsibility",
    "article": "Article",
    "obligation": "Obligation",
    "movementType": "Movement type",
    "packagingType": "Packaging type",
    "reason": "Reason",
    "creationPending": "Creation pending ...",
    "download": "Download"
  },
  "declarationExport": {
    "file": {
      "reporting": "Reporting",
      "licensing": "Licensing",
      "payment": "Payment",
      "systemProvider": "System provider",
      "month": "Month",
      "forecast": {
        "header": "Forecast",
        "value": "Yes"
      },
      "glass": "Glass (kg)",
      "fiber": "Fiber (kg)",
      "ferrousMetal": "Ferrous metal (kg)",
      "aluminum": "Aluminum (kg)",
      "plastic": "Plastic (kg)",
      "beverageCarton": "Beverage carton (kg)",
      "other": "Other (kg)",
      "otherComposite": "Other composite (kg)",
      "placings": "Placings",
      "preview": "[Preview]",
      "responsible_type": {
        "Customers": "Customers",
        "Producer": "Producer",
        "Supplier": "Supplier",
        "Customer": "Customer"
      }
    },
    "title": "Export by responsibility",
    "subtitle": "All placings, aggregated by",
    "producer": "Who brought placings into circulation?",
    "licenser": "Who is licensing the placings?",
    "payment": "Who pays the licensing costs?",
    "reason": "What is the reason for placing distribution?",
    "download": "Download",
    "volumes": "Volumes"
  },
  "exportDialog": {
    "title": "Export",
    "buttonText": "Export"
  },
  "finishStep": {
    "title": "Finished",
    "successHint": "The declaration process has been completed.<br /> You can export the reported placings for further analysis or processing.",
    "thankYou": "Thank you!"
  },
  "csuFinishStep": {
    "title": "Finished",
    "successHint": "The calculation of your detailed declaration has been completed.\n\nPlease download the results and continue in your MyLéko portal.\nYour downloaded file also shows the results for every line of your declaration.\n\nNotice: To create an additional declaration, click on the button “all producers” above and add a new producer or change an existing one.\n\nThank you for using Calculate!",
    "download": "Download"
  },
  "licenseDetail": {
    "reportInfoTitle": "Please report the placings to the system providers",
    "reportInfoText": "<strong>{{producer}}</strong> licenses the following placings. The placings are licensed with one or more system providers.",
    "markAsReported": "Mark as reported",
    "systemProvider": "System provider",
    "distributedBy": "Distributed by"
  },
  "licenseList": {
    "licensedBy": "Company",
    "status": "Status"
  },
  "licenseListRow": {
    "reported": "reported",
    "notReported": "not reported",
    "showReport": "Show report",
    "reportToSystemProvider": "Report to system provider",
    "noReportRequired": "Report not required",
    "addContract": "Add Contract"
  },
  "licenseStep": {
    "showAllDistributors": "Show all companies"
  },
  "thirdPartyLicensingForm": {
    "systemProvider": "System provider",
    "delete": "Delete",
    "save": "Save",
    "confirmDeletion": "Are you sure that you want to delete this entry?",
    "hasWeightsLabel": "The licensee has shared the volumes that have been licensed"
  },
  "thirdPartyLicensingStep": {
    "reporting": "Reporting",
    "licensing": "Licensing",
    "systemProvider": "System provider", 
    "source": "Source",
    "licensee": "Licensee",
    "calculate": "Calculate",
    "noSystem": "No system provider selected",
    "noValues": "No information has been reported by the licensee",
    "title": "Please compare the calculated weights with those reported by the licensing party",
    "subtitle": "Adjust the calculated weights in accordance with the weights provided to the system provider, taking into account the possible difference between the computed and licensed weights.\n\nSave the updated weights for further use in subsequent stages of the declaration process.",
    "thirdPartyLicensingReportingIntervalHint": "Please be advised that the reporting interval for these volumes is determined by the contracts of the licensees with their system providers."
  },
  "reviewStep": {
    "title": "Please check the calculated placings for plausibility.",
    "subtitle": {
      "de": "If the weights are not plausible you can withdraw the declaration, revise the data and create a new declaration.\nIf the weights are plausible you can release the declaration at this point.\nUpon release all weights will be permanently saved and can not be changed anymore.\nAll persons responsible for reporting to LUCID or system providers will be notified about the release.",
      "at": "If the weights are not plausible you can withdraw the declaration, revise the data and create a new declaration.\nIf the weights are plausible you can release the declaration at this point.\nUpon release all weights will be permanently saved and can not be changed anymore."
    },
    "company": "Company",
    "notObligated": "No obligation for the selected period",
    "responsibilities": {
      "reporting": "Reporting",
      "licensing": "Licensing",
      "payment": "Payment"
    },
    "packagingKinds": {
      "household": "Household",
      "commercial": "Commercial"
    },
    "compareWith": "Compare with"
  },
  "reportDetail": {
    "showDetails": "Show details"
  },
  "reportDetailPerLicenser": {
    "licensedBy": "Licensed by",
    "systemProvider": "System provider"
  },
  "reportDetailPerSystem": {
    "title": "Please report the weights to LUCID",
    "subtitle": "<strong>{{producer}}</strong> brought the following weights into circulation.<br />\nThe weights will be licensed with one or more system providers and have to be reported to LUCID per system provider as well.",
    "thirdPartyLicensingReportingIntervalHint": "Please be advised that the reporting interval for these volumes is determined by the contracts of the licensees with their system providers.",
    "markAsReported": "Mark as reported",
    "systemProvider": "System provider"
  },
  "adjustStep": {
    "showAllProducers": "Show all companies"
  },
  "adjustList": {
    "producer": "Company",
    "packagingType": "Packaging type"
  },
  "adjustListRow": {
    "showPlacings": "Manage placings",
    "noReportRequired": "Report not required",
    "addContract": "Add Contract"
  },
  "reportList": {
    "distributedBy": "Company",
    "status": "Status"
  },
  "reportListRow": {
    "reported": "reported",
    "notReported": "not reported",
    "showReport": "Show report",
    "reportToLUCID": "Report to LUCID",
    "noReportRequired": "Report not required",
    "addContract": "Add Contract"
  },
  "reportStep": {
    "showAllDistributors": "Show all companies"
  },
  "declarationErrors": {
    "download": {
      "description": "Description",
      "branchId": "Branch ID",
      "articleId": "Article ID",
      "supplierId": "Supplier ID",
      "quantity": "Quantity",
      "issues": "Issues"
    },
    "highPriorityTitle": "Issues with the highest priority",
    "highPrioritySubtitle": "There are {{number}} high priority issues.",
    "downloadTitle": "Download all",
    "downloadSubtitle": "You can download a CSV-file (UTF-8) with the complete list of issues.",
    "downloadButtonText": "Download CSV (UTF-8)"
  },
  "declarationErrorsTable": {
    "issues": {
      "branchRequired": "The branch could not be found.",
      "producerIdRequired": "The ID of the producer is not defined on the branch.",
      "articleRequired": "Article could not be found.",
      "servicePackagingRequired": "Service packaging could not be found.",
      "supplierCountryRequired": "The Country of origin for the article could not be determined.",
      "supplierIdRequired": "The supplier could not be determined.",
      "supplierIncoTermRequired": "The supplier INCO Term could not be determined.",
      "delegationOfLicensingRequired": "Delegation of licensing could not be determined.",
      "delegationOfPaymentRequired": "Delegation of payment could not be determined.",
      "salePackagingRequired": "Sales packaging could not be found.",
      "salePackagingWeightsRequired": "Sales packaging weight is 0 kg.",
      "servicePackagingWeightsRequired": "Service packaging weight is 0 kg.",
      "articleQuantityRequired": "Article quantity was not defined.",
      "shipToCountryRequired": "The country to which delivery is made is missing for at least one movement.",
      "customerDelegatesObligationRequired": "Information about whether there is a delegation agreement for service packaging with the customer is missing for at least one movement.",
      "companyRequired": "Information about which company is responsible for the movement is missing.",
      "producerOnPackagingRequired": "The ID of the producer on the packaging is not defined.",
      "manufacturersRequired": "Information about the manufacturers of the article is missing to detect the first placement of the packaging on the market.",
      "supplierRequired": "Supplier could not be found.",
      "householdPackagingRequired": "Household packaging could not be found.",
      "householdPackagingWeightsRequired": "Household packaging weight is 0 kg.",
      "commercialPackagingRequired": "Commercial packaging could not be found.",
      "commercialPackagingWeightsRequired": "Commercial packaging weight is 0 kg."
    },
    "description": "Description",
    "branchId": "Branch ID",
    "articleId": "Article ID",
    "supplierId": "Supplier ID",
    "quantity": "Quantity"
  },
  "issuesStep": {
    "issuesTitle": "Issues have been identified that prevent a complete detection of the distributed packaging weights",
    "issuesSubtitle": "The issues have been prioritized automatically. Please revise your data to fix these issues.<br />\nTo resolve all issues the current weights have to be withdrawn and recalculated.",
    "noIssuesTitle": "No issues have been detected",
    "noIssuesSubtitle": "You can proceed with the release step.",
    "totalCosts": "Total costs"
  },
  "csuValidateStep": {
    "noIssuesTitle": "The detailed declaration has been calculated successfully.",
    "noIssuesSubtitle": "Click Next to proceed. Click Withdraw if you want to import again.",
    "totalCosts": "Total cost"
  },
  "validationTable": {
    "membershipId": "Membership ID",
    "costs": "Costs"
  },
  "reportHistory": {
    "showButtonTitle": "Show report history",
    "modalTitle": "Report history"
  },
  "cappingOfReturns": "Capping of deductions"
}