import { AggregatedPackagingWeightsAustria } from '../../../api';

export function getEmptyWeights(): AggregatedPackagingWeightsAustria {
  return {
    aluminumCommercial: 0,
    aluminumHouseHold: 0,
    beverageCartonHouseHold: 0,
    ceramicCommercial: 0,
    ceramicHouseHold: 0,
    compositeCommercial: 0,
    compositeHouseHold: 0,
    epsCommercial: 0,
    ferrousMetalCommercial: 0,
    ferrousMetalHouseHold: 0,
    fiberCommercial: 0,
    fiberHouseHold: 0,
    foilsCommercial: 0,
    glassHouseHold: 0,
    hollowBodiesCommercial: 0,
    organicCommercial: 0,
    organicHouseHold: 0,
    plasticHouseHold: 0,
    textileCommercial: 0,
    textileHouseHold: 0,
    woodCommercial: 0,
    woodHouseHold: 0,
  };
}
