import { isProducer, SystemForLicensing } from '../helpers';
import { PackagingWeights, Responsibility, Responsible } from '../../../../api';
import { from, first, IterableX, reduce } from 'ix/iterable';
import { filter, groupBy, map, memoize } from 'ix/iterable/operators';
import { serializeKey } from '../../../../serializeKey';
import { getEmptyWeights } from '../getEmptyWeights';
import { addWeights } from '../addWeights';

export type LicenseAggregation = {
  key: string;
  reporting: Responsible;
  system: SystemForLicensing;
  weights: PackagingWeights;
};

export function aggregate(
  getSystemForLicensing: (reporting: Responsible, licensing: Responsible) => SystemForLicensing,
  producerId: string,
  responsibilities: Responsibility[]
): IterableX<LicenseAggregation> {
  return from(responsibilities).pipe(
    filter(r => isProducer(r.licensing) && r.licensing.Fields[0] === producerId),
    groupBy(r => serializeKey(r.reporting)),
    map(g => {
      const f = first(g)!;
      const a: LicenseAggregation = {
        key: g.key,
        reporting: f.reporting,
        system: getSystemForLicensing(f.reporting, { Case: 'Producer', Fields: [producerId] }),
        weights: reduce(g, (a, c) => addWeights(a, c.weights), null as PackagingWeights | null) ?? getEmptyWeights(),
      };
      return a;
    }),
    memoize()
  );
}
