import { ReviewAggregation } from './aggregate';
import { useState } from 'react';
import { Icon } from '../../../../shared/components/Icon';
import { ReviewWeightsRowCells } from './ReviewWeightsRowCells';
import { map } from 'ix/iterable/operators';
import { useTranslation } from 'react-i18next';
import { serializeKey } from '../../../../serializeKey';
import { formatResponsibilityPeriod } from './formatResponsibilityPeriod';
import { ResponsibleView } from '../../ResponsibleView';
import { ForecastCell } from '../../ForecastCell';

export function ReviewListRow({ a }: { a: ReviewAggregation }) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <tr style={{ cursor: 'pointer' }} onClick={() => setExpanded(e => !e)}>
        <td className={`align-middle`}>
          <button className="bg-transparent border-0 text-primary">
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </button>
        </td>
        <td className="align-middle">
          <ResponsibleView responsible={a.responsible} />
        </td>
        <ForecastCell forecast={a.weights.forecast} />
        <ReviewWeightsRowCells weights={a.weights.current} comparison={a.weights.comparison} />
      </tr>
      {expanded && (
        <>
          {a.months.pipe(
            map(m => (
              <tr key={serializeKey(m.period)} className="table-light">
                <td className="border-start"></td>
                <td className="px-4">{formatResponsibilityPeriod(t, m.period)}</td>
                <ForecastCell forecast={m.weights.forecast} />
                <ReviewWeightsRowCells weights={m.weights.current} comparison={m.weights.comparison} />
              </tr>
            ))
          )}
          <tr>
            <td colSpan={10}></td>
          </tr>
        </>
      )}
    </>
  );
}
