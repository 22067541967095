﻿import { Outlet } from 'react-router';
import { WeightsHeaderCells } from '../../../../../WeightsHeaderCells';
import { PlacingRow } from './PlacingRow';
import { Toolbar } from '../../../../../shared/components/Toolbar';
import { getPlacings, PackagingWeights, PlacingData } from '../../../../../api';
import { InfoScreen } from '../../../../../shared/components/InfoScreen';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '../../../../../shared/components/Icon';
import { NavLink } from 'react-router-dom';
import { useTenantQuery } from '../../../../../shared/hooks/useTenantQuery';
import { useQueryValue } from '../../../../../shared/hooks/useQueryValue';
import { usePeriodParam, useYearParam } from '../../../../../shared/hooks/useParam';
import { getPeriodContains } from '../../../../../helpers';
import { PlacingWeightsRowCells } from './PlacingWeightsRowCells';
import { getEmptyWeights } from '../../getEmptyWeights';
import { addWeights } from '../../addWeights';

export interface Props {
  producerId: string;
}

export function PlacingsOfProducer({ producerId }: Props) {
  const { t } = useTranslation('placings');

  const [year, period] = [useYearParam(), usePeriodParam()];

  const placingsQuery = useTenantQuery(getPlacings, year, false);
  const placings = useQueryValue(placingsQuery);

  const rows = Object.entries(placings).filter(([, p]) => p.producerId === producerId);

  const includedInActivePeriod = (row: [string, PlacingData]) => {
    const placingPeriod = row[1].period;
    return getPeriodContains(period, placingPeriod);
  };

  const includedRows = rows.filter(includedInActivePeriod);

  const includedRowsWeight = includedRows.reduce<PackagingWeights | null>((s, r) => addWeights(s, r[1].weights), null);

  const notIncludedInActivePeriod = (row: [string, PlacingData]) => !includedInActivePeriod(row);

  const notIncludedRows = rows.filter(notIncludedInActivePeriod);

  return (
    <>
      <div className="bg-light rounded">
        <InfoScreen title={t('placingsOfYear.title')} icon="info-circle" color="primary">
          <p>
            <Trans t={t}>{t('placingsOfYear.subtitle')}</Trans>
          </p>
        </InfoScreen>
      </div>
      <table className="table table-striped table-hover">
        <thead className="sticky-top bg-white">
          <tr>
            <th className="col-2 w-100">{t('placingsOfYear.description')}</th>
            <th className="col-1">{t('placingsOfYear.period')}</th>
            <th className="col-1">{t('placingsOfYear.packagingType')}</th>
            <WeightsHeaderCells />
            <th></th>
          </tr>
        </thead>
        <tbody>
          {includedRows.map(([id, data]) => (
            <PlacingRow key={id} year={year} placingId={id} data={data} period={period} />
          ))}
          <tr>
            <td className="border-top" colSpan={3}></td>
            <PlacingWeightsRowCells
              weights={includedRowsWeight ?? getEmptyWeights()}
              cellClassName="border-top text-secondary fw-bold"
            />
            <td className="border-top text-secondary"></td>
          </tr>
        </tbody>
        {notIncludedRows.length > 0 ? (
          <>
            <tbody>
              <tr>
                <td colSpan={12} className={'text-muted text-center small fw-normal p-0'}>
                  <div className="bg-white pt-4 pb-2">{t('placingsOfYear.notIncludedInPeriod')}</div>
                </td>
              </tr>
            </tbody>
            <tbody>
              {notIncludedRows.map(([id, data]) => (
                <PlacingRow
                  key={id}
                  year={year}
                  placingId={id}
                  data={data}
                  period={period}
                  cellClassName="opacity-75 text-muted"
                />
              ))}
            </tbody>
          </>
        ) : (
          <></>
        )}
      </table>
      <Toolbar>
        <NavLink to="./add" className="btn btn-primary text-white">
          <>
            <Icon name="plus-lg" /> {t('addButton.buttonText')}
          </>
        </NavLink>
      </Toolbar>
      <Outlet context={placings} />
    </>
  );
}
