﻿import { Outlet, RouteObject, useOutletContext } from 'react-router';
import { booleanParam, useSearchParams } from '../../../../shared/hooks/useSearchParams';
import { ReportDetailPerLicenser } from './ReportDetailPerLicenser';
import { useProducers } from '../../../producers/Producers';
import { Icon } from '../../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { Actions } from '../Actions';
import { NavLink } from 'react-router-dom';
import { DeclarationResultGermany } from '../../../../api';
import { ContractEdit } from '../../../contracts/de/ContractEdit';
import { usePeriodParam, useStringParams, useYearParam } from '../../../../shared/hooks/useParam';
import { useThirdPartyLicensing } from '../thirdPartyLicensing/ThirdPartyLicensing';
import { useProducersThatHaveToReport } from './useProducersThatHaveToReport';
import { ThirdPartyLicensingEdit } from '../thirdPartyLicensing/ThirdPartyLicensingEdit';
import { aggregate } from './aggregate';
import { useGetSystemForLicensingFn } from '../UseGetSystemForLicensingFn';
import { ReportDetailPerSystem } from './ReportDetailPerSystem';
import { getResponsibilitiesWithThirdPartyLicensing } from '../getResponsibilitiesWithThirdPartyLicensing';

export const reportDetailRoute: RouteObject = {
  path: ':producerId',
  element: <ReportDetail />,
  children: [
    {
      path: 'contracts/edit/:contractProducerId',
      element: <ContractEdit />,
    },
    {
      path: 'thirdPartyLicensing/edit/:licenseeType/:licenseeId',
      element: <ThirdPartyLicensingEdit />,
    },
  ],
};

export function ReportDetail() {
  const { t } = useTranslation('reports');

  const [period, year] = [usePeriodParam(), useYearParam()];
  const { producerId } = useStringParams<'producerId'>();

  const declaration = useOutletContext<DeclarationResultGermany>();

  const producersThatHaveToReport = useProducersThatHaveToReport(period, declaration, true);

  const responsibilities = getResponsibilitiesWithThirdPartyLicensing(
    period,
    declaration.responsibilities,
    useThirdPartyLicensing().all
  );

  const aggregations = aggregate(useGetSystemForLicensingFn(), producerId, responsibilities);

  const [details, setDetails] = useSearchParams('details', booleanParam(false));

  const reportDetail = details ? (
    <ReportDetailPerLicenser producerId={producerId} aggregations={aggregations} />
  ) : (
    <ReportDetailPerSystem
      declarationId={declaration.declarationId}
      producerId={producerId}
      aggregations={aggregations}
    />
  );

  const producers = useProducers();
  const producer = producers.get(producerId);

  return (
    <>
      {producersThatHaveToReport.length > 1 ? (
        <div className="my-4">
          <NavLink className="btn btn-outline-secondary" to=".." end>
            <>
              <Icon name="chevron-left" />
              {t('reportStep.showAllDistributors')}
            </>
          </NavLink>
        </div>
      ) : (
        <Actions year={year} period={period} declaration={declaration} />
      )}
      <div className="my-3 d-flex align-items-center">
        <div style={{ width: '5rem', lineHeight: '4rem' }} className="text-center">
          <Icon name="building" className="fs-1" />
        </div>
        <div className="flex-grow-1">
          <h1 className="h2 my-0">{producer?.name ?? producerId}</h1>
          {producer && (
            <div className="text-muted">
              ID: {producer.id} | LUCID: {producer.lucidNumber}
            </div>
          )}
        </div>
        <div>
          <div className="form-check form-switch d-inline-block">
            <input
              className="form-check-input"
              type="checkbox"
              id="aggregateBySystem"
              checked={details}
              onChange={e => setDetails(e.currentTarget.checked)}
            />
            <label className="form-check-label" htmlFor="aggregateBySystem">
              {t('reportDetail.showDetails')}
            </label>
          </div>
        </div>
      </div>
      {reportDetail}
      <Outlet />
    </>
  );
}
