﻿import { PackagingWeights } from '../../../../api';
import { ReviewWeightsCell } from '../../ReviewWeightsCell';

export interface Props {
  weights: PackagingWeights | null;
  comparison: PackagingWeights | null;
}

export function ReviewWeightsRowCells(props: Props) {
  return (
    <>
      <ReviewWeightsCell weightKey="glass" weights={props.weights} comparison={props.comparison} />
      <ReviewWeightsCell weightKey="fiber" weights={props.weights} comparison={props.comparison} />
      <ReviewWeightsCell weightKey="ferrousMetal" weights={props.weights} comparison={props.comparison} />
      <ReviewWeightsCell weightKey="aluminum" weights={props.weights} comparison={props.comparison} />
      <ReviewWeightsCell weightKey="plastic" weights={props.weights} comparison={props.comparison} />
      <ReviewWeightsCell weightKey="beverageCarton" weights={props.weights} comparison={props.comparison} />
      <ReviewWeightsCell weightKey="otherComposite" weights={props.weights} comparison={props.comparison} />
      <ReviewWeightsCell weightKey="other" weights={props.weights} comparison={props.comparison} />
    </>
  );
}
