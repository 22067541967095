import { ResponsibilityKind } from './aggregate';
import { useTranslation } from 'react-i18next';
import { NavLink, useSearchParams as useSearchParamsRouter } from 'react-router-dom';

export function ResponsibilityKindNavItem({ kind }: { kind: ResponsibilityKind }) {
  const { t } = useTranslation('reports');
  const [search] = useSearchParamsRouter();
  return (
    <li className="nav-item">
      <NavLink
        className="nav-link nav-link-flex-height px-4"
        to={{ pathname: `../review/${kind}`, search: search.toString() }}>
        {t(`reviewStep.responsibilities.${kind}`)}
      </NavLink>
    </li>
  );
}
