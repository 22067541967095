import { Period, Responsibility, ThirdPartyLicensing } from '../../../api';
import { isForecastedPeriod } from '../isForecastedPeriod';

/**
 * Maps a third party licensing to a responsibility, or returns null if the weights are defined.
 * @param period The period.
 * @param licensing The third party licensing.
 */
export function thirdPartyLicensingToResponsibilityOrNull(
  period: Period,
  licensing: ThirdPartyLicensing
): Responsibility | null {
  return licensing.weights == null
    ? null
    : {
        forecast: isForecastedPeriod(period),
        period: 'ThirdPartyLicensing',
        reporting: { Case: 'Producer', Fields: [licensing.producerId] },
        payment: { Case: 'Producer', Fields: [licensing.producerId] },
        licensing: { Case: licensing.licensee.Case, Fields: licensing.licensee.Fields },
        weights: licensing.weights,
      };
}
