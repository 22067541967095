import { AggregatedPackagingWeightsAustria, Responsible } from '../../../../api';
import { ResponsibleView } from '../../ResponsibleView';
import { WeightsRowCells } from '../WeightsRowCells';

import { getEmptyWeights } from '../getEmptyWeights';

export function LicenseDetailRow({
  weights,
  reporting,
}: {
  producerId: string;
  weights: AggregatedPackagingWeightsAustria | null;
  reporting: Responsible;
}) {
  return (
    <tr>
      <td className="align-middle">
        <ResponsibleView responsible={reporting} />
      </td>
      <WeightsRowCells weights={weights ?? getEmptyWeights()} />
    </tr>
  );
}
