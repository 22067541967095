﻿import { Responsible } from '../../api';
import { expectNever } from '../../helpers';
import { ProducerInfo } from '../producers/ProducerInfo';
import { SupplierInfo } from './SupplierInfo';
import { CustomerInfo } from './CustomerInfo';
import { CustomersInfo } from './CustomersInfo';

/**
 * Shows a company that is responsible for something.
 */
export function ResponsibleView({ responsible }: { responsible: Responsible }) {
  if (responsible === 'Customers') {
    return <CustomersInfo />;
  }

  const c = responsible.Case;

  if (c === 'Producer') {
    const producerId = responsible.Fields[0];
    return <ProducerInfo producerId={producerId} />;
  }

  if (c === 'Supplier') {
    const supplierId = responsible.Fields[0];
    return <SupplierInfo supplierId={supplierId} />;
  }

  if (c === 'Customer') {
    const customerId = responsible.Fields[0];
    return <CustomerInfo name={customerId} />;
  }

  expectNever(c);
}
