﻿import { AggregatedPackagingWeightsAustria, DeclarationResultAustria, Responsible } from '../../../../api';
import { Outlet, RouteObject, useOutletContext } from 'react-router';
import { useProducers } from '../../../producers/Producers';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { Icon } from '../../../../shared/components/Icon';
import { selectAndNumericCompare } from '../../selectAndNumericCompare';
import { useReports } from '../../Reports';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { Trans, useTranslation } from 'react-i18next';
import { ContractEdit } from '../../../contracts/at/ContractEdit';
import { Actions } from '../Actions';
import { NavLink } from 'react-router-dom';
import { usePeriodParam, useStringParams, useYearParam } from '../../../../shared/hooks/useParam';
import { SortableWeightsHeaderCells } from '../SortableWeightsHeaderCells';
import { ReportHistory } from '../../de/ReportHistory';
import { AuditorExportButton } from '../export/AuditorExport';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { useCompareResponsible } from '../../de/CompareResponsible';
import { LicenseAggregation, aggregate } from './aggregate';
import { count, toArray } from 'ix/iterable';
import { LicenseDetailRow } from './LicenseDetailRow';
import { getEmptyWeights } from '../getEmptyWeights';

export const licenseDetailRoute: RouteObject = {
  path: ':producerId',
  element: <LicenseDetail />,
  children: [
    {
      path: 'contracts/edit/:contractProducerId',
      element: <ContractEdit />,
    },
  ],
};

export function LicenseDetail() {
  const { t } = useTranslation('reports');

  const [period, year] = [usePeriodParam(), useYearParam()];
  const { producerId } = useStringParams<'producerId'>();
  const declaration = useOutletContext<DeclarationResultAustria>();

  const aggregations = aggregate(producerId, declaration.responsibilities);

  const producers = useProducers().all;

  const reports = useReports();
  const report = reports.get(declaration.declarationId, producerId);

  const compareResponsible = useCompareResponsible();

  function compareLicensing(a: LicenseAggregation, b: LicenseAggregation) {
    return compareResponsible(a.responsible, b.responsible);
  }

  const emptyResponsible: Responsible = {
    Case: 'Producer',
    Fields: [producerId],
  };

  const emptyWeights: AggregatedPackagingWeightsAustria = getEmptyWeights();

  const sorting = useSorting<LicenseAggregation, 'responsible' | keyof AggregatedPackagingWeightsAustria>(
    toArray(aggregations),
    {
      responsible: compareLicensing,
      glassHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.glassHouseHold),
      fiberHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.fiberHouseHold),
      ferrousMetalHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.ferrousMetalHouseHold),
      aluminumHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.aluminumHouseHold),
      plasticHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.plasticHouseHold),
      beverageCartonHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.beverageCartonHouseHold),
      compositeHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.compositeHouseHold),
      ceramicHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.ceramicHouseHold),
      woodHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.woodHouseHold),
      textileHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.textileHouseHold),
      organicHouseHold: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.organicHouseHold),
      fiberCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.fiberCommercial),
      ferrousMetalCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.ferrousMetalCommercial),
      aluminumCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.aluminumCommercial),
      foilsCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.foilsCommercial),
      hollowBodiesCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.hollowBodiesCommercial),
      epsCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.epsCommercial),
      compositeCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.compositeCommercial),
      ceramicCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.ceramicCommercial),
      woodCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.woodCommercial),
      textileCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.textileCommercial),
      organicCommercial: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.organicCommercial),
    },
    { key: 'responsible', direction: 'asc' }
  );

  const producer = producers[producerId];

  return (
    <>
      {count(aggregations) === 1 ? (
        <Actions year={year} period={period} declaration={declaration} />
      ) : (
        <div className="my-4">
          <NavLink className="btn btn-outline-secondary" to=".." end>
            <>
              <Icon name="chevron-left" />
              {t('reportStep.showAllDistributors')}
            </>
          </NavLink>
        </div>
      )}
      <div className="my-3 d-flex align-items-center">
        <div style={{ width: '5rem', lineHeight: '4rem' }} className="text-center">
          <Icon name="building" className="fs-1" />
        </div>
        <div className="flex-grow-1">
          <h1 className="h2 my-0">{producer?.name ?? producerId}</h1>
          {producer && <div className="text-muted">ID: {producer.id}</div>}
        </div>
      </div>
      <div className="bg-light rounded mb-4">
        <InfoScreen icon="send" color="primary" title={t('licenseDetail.reportInfoTitle')}>
          <p>
            <Trans t={t}>{t('licenseDetail.reportInfoText', { producer: producer?.name ?? producerId })}</Trans>
          </p>
          <div className="form-check form-switch d-inline-block ms-4">
            <input
              className="form-check-input"
              id="cb-report"
              type="checkbox"
              checked={!!report?.proReportedAt}
              onChange={e => reports.report(declaration.declarationId, producerId, 'Pro', e.target.checked)}
            />
            <label className="form-check-label" htmlFor="cb-report">
              {t('licenseDetail.markAsReported')}
            </label>
          </div>
          <div className="mt-2">
            <ReportHistory
              producerId={producerId}
              type="Pro"
              renderDownloadButton={declarationId => <AuditorExportButton declarationId={declarationId} />}
            />
          </div>
        </InfoScreen>
      </div>
      <div className="table-responsive">
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <SortableHeaderCell sorting={sorting} sortKey="responsible" className="text-nowrap">
                {t('licenseDetail.distributedBy')}
              </SortableHeaderCell>
              <SortableWeightsHeaderCells household commercial sorting={sorting} />
            </tr>
          </thead>
          <tbody>
            {sorting.sorted.length > 0 ? (
              sorting.sorted.map(row => (
                <LicenseDetailRow
                  key={row.key}
                  reporting={row.responsible}
                  producerId={producerId}
                  weights={row.weights}
                />
              ))
            ) : (
              <LicenseDetailRow reporting={emptyResponsible} producerId={producerId} weights={emptyWeights} />
            )}
          </tbody>
        </table>
      </div>
      <Outlet />
    </>
  );
}
