﻿import { DeclarationKind, Period, ProducerData, ResponsibilityAustria, Responsible } from '../../../../api';
import { useProducers } from '../../../producers/Producers';
import { useContracts } from '../../../contracts/Contracts';
import { Icon } from '../../../../shared/components/Icon';
import {
  getPeriodForFileName,
  getResponsibleIdForCsv,
  getResponsibleNameForCsv,
  getResponsibleTypeForCsv,
  getSystemNameForCsv,
  getWeightForCsv,
} from '../csv';
import { exportAsCsvUtf8 } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { useSuppliers } from '../../../suppliers/suppliers';
import { TFunction } from 'i18next';
import { useSystems } from '../../../systems/Systems';

function exportAsCsv(
  t: TFunction,
  year: number,
  period: Period,
  producers: Record<string, ProducerData>,
  suppliers: Record<string, string>,
  responsibilities: ResponsibilityAustria[]
) {
  const header = [
    t('declarationExport.file.licensingType'),
    t('declarationExport.file.licensingName'),
    t('declarationExport.file.licensingId'),
    t('declarationExport.file.month'),
    t('global:fractions.at.glassHouseHold'),
    t('global:fractions.at.fiberHouseHold'),
    t('global:fractions.at.ferrousMetalHouseHold'),
    t('global:fractions.at.aluminumHouseHold'),
    t('global:fractions.at.plasticHouseHold'),
    t('global:fractions.at.beverageCartonHouseHold'),
    t('global:fractions.at.compositeHouseHold'),
    t('global:fractions.at.ceramicHouseHold'),
    t('global:fractions.at.woodHouseHold'),
    t('global:fractions.at.textileHouseHold'),
    t('global:fractions.at.organicHouseHold'),
    t('global:fractions.at.fiberCommercial'),
    t('global:fractions.at.ferrousMetalCommercial'),
    t('global:fractions.at.aluminumCommercial'),
    t('global:fractions.at.foilsCommercial'),
    t('global:fractions.at.hollowBodiesCommercial'),
    t('global:fractions.at.epsCommercial'),
    t('global:fractions.at.compositeCommercial'),
    t('global:fractions.at.ceramicCommercial'),
    t('global:fractions.at.woodCommercial'),
    t('global:fractions.at.textileCommercial'),
    t('global:fractions.at.organicCommercial'),
  ];

  const rows = responsibilities.map(a => [
    getResponsibleTypeForCsv(t, producers, a.licensing),
    getResponsibleNameForCsv(producers, suppliers, a.licensing),
    getResponsibleIdForCsv(producers, a.licensing),

    a.month === null ? '' : a.month.toString(),
    getWeightForCsv(a.weights.glassHouseHold),
    getWeightForCsv(a.weights.fiberHouseHold),
    getWeightForCsv(a.weights.ferrousMetalHouseHold),
    getWeightForCsv(a.weights.aluminumHouseHold),
    getWeightForCsv(a.weights.plasticHouseHold),
    getWeightForCsv(a.weights.beverageCartonHouseHold),
    getWeightForCsv(a.weights.compositeHouseHold),
    getWeightForCsv(a.weights.ceramicHouseHold),
    getWeightForCsv(a.weights.woodHouseHold),
    getWeightForCsv(a.weights.textileHouseHold),
    getWeightForCsv(a.weights.organicHouseHold),
    getWeightForCsv(a.weights.fiberCommercial),
    getWeightForCsv(a.weights.ferrousMetalCommercial),
    getWeightForCsv(a.weights.aluminumCommercial),
    getWeightForCsv(a.weights.foilsCommercial),
    getWeightForCsv(a.weights.hollowBodiesCommercial),
    getWeightForCsv(a.weights.epsCommercial),
    getWeightForCsv(a.weights.compositeCommercial),
    getWeightForCsv(a.weights.ceramicCommercial),
    getWeightForCsv(a.weights.woodCommercial),
    getWeightForCsv(a.weights.textileCommercial),
    getWeightForCsv(a.weights.organicCommercial),
  ]);

  exportAsCsvUtf8(`AT_${getPeriodForFileName(year, period)}_${t('declarationExport.volumes')}.csv`, header, rows);
}

export interface Props {
  year: number;
  period: Period;
  kind: DeclarationKind;
  responsibilities: ResponsibilityAustria[];
}

export function DeclarationExport({ year, period, kind, responsibilities }: Props) {
  const producers = useProducers();
  const suppliers = useSuppliers();

  const { t } = useTranslation('reports');

  return (
    <section>
      <h1 className="h5">{t('declarationExport.title')}</h1>
      <p className="fw-light fs-5 mt-2">{t('declarationExport.subtitle')}</p>
      <ul>
        <li>{t('declarationExport.licenser')}</li>
      </ul>
      <div className="mt-4 mb-2  text-end">
        <button
          className="btn btn-secondary"
          onClick={() => exportAsCsv(t, year, period, producers.all, suppliers, responsibilities)}>
          <Icon name="download" /> {t('declarationExport.download')}
        </button>
      </div>
    </section>
  );
}
