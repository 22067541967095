import { PackagingWeights } from '../../../api';

export function getEmptyWeights(): PackagingWeights {
  return {
    glass: 0,
    fiber: 0,
    ferrousMetal: 0,
    aluminum: 0,
    plastic: 0,
    beverageCarton: 0,
    otherComposite: 0,
    other: 0,
  };
}
