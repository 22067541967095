﻿import { Availability, Period } from '../../api';
import { Icon } from '../../shared/components/Icon';
import { useTranslation } from 'react-i18next';

const monthKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
const itemStyle = { width: '3rem', lineHeight: '2.5rem', maxHeight: '2.5rem' };

function AvailabilityItem({ availability }: { availability: Availability }) {
  const bg = availability === 'Available' ? 'success' : availability === 'NotAvailable' ? 'secondary' : 'warning';
  const iconName = availability === 'Available' ? 'check' : availability === 'NotAvailable' ? 'x' : 'magic';

  return (
    <div style={itemStyle} className={`rounded me-2 mt-2 bg-${bg}`}>
      <Icon name={iconName} className="text-white fs-5" />
    </div>
  );
}

export interface Props {
  year: number;
  period: Period;
  availability: { month: number; availability: Availability }[];
}

function getYear(year: number, period: Period) {
  return period === 'InitialForecastYear' ? year - 1 : year;
}

export function AvailabilityView({ year, period, availability }: Props) {
  const { t } = useTranslation('reports');
  return (
    <section className="d-inline-block border rounded p-4">
      <h1 className="h6">{t('availabilityView.heading')}</h1>
      <div className="fs-4 mt-4">{getYear(year, period)}</div>
      <div className="d-flex p-1 justify-content-center text-center">
        {availability.map(a => (
          <div key={a.month}>
            <div style={itemStyle} className="rounded me-2">
              {t(`global:months.${monthKeys[a.month - 1]}`)}
            </div>
            <AvailabilityItem availability={a.availability} />
          </div>
        ))}
      </div>
    </section>
  );
}
