import { Period, Responsibility, ThirdPartyLicensing } from '../../../api';
import { thirdPartyLicensingToResponsibilityOrNull } from './thirdPartyLicensingToResponsibilityOrNull';
import { notNullOrUndefined } from '../../../helpers';
import { serializeKey } from '../../../serializeKey';

export function getResponsibilitiesWithThirdPartyLicensing(
  period: Period,
  responsibilities: Responsibility[],
  thirdPartyLicensings: Record<string, ThirdPartyLicensing>
): Responsibility[] {
  const thirdPartyResponsibilities: Responsibility[] = Object.values(thirdPartyLicensings)
    .map(t => thirdPartyLicensingToResponsibilityOrNull(period, t))
    .filter(notNullOrUndefined);

  const filtered = responsibilities.filter(responsibility => {
    if (typeof responsibility.reporting !== 'object' || responsibility.reporting === null) {
      return true;
    }

    if (typeof responsibility.licensing !== 'object' || responsibility.licensing === null) {
      return true;
    }

    if (responsibility.licensing.Case === 'Producer' || responsibility.reporting.Case !== 'Producer') {
      return true;
    }

    return !thirdPartyResponsibilities.some(
      thirdPartyLicensing =>
        serializeKey(thirdPartyLicensing.reporting) === serializeKey(responsibility.reporting) &&
        serializeKey(thirdPartyLicensing.licensing) === serializeKey(responsibility.licensing)
    );
  });

  return [...filtered, ...thirdPartyResponsibilities];
}
