import { useSystems } from '../../systems/Systems';
import { SystemForLicensing } from './helpers';

export function useGetSystemNameFn() {
  const systems = useSystems();
  return (systemForLicensing: SystemForLicensing) => {
    if (systemForLicensing.type === 'System') {
      const system = systems.get(systemForLicensing.systemId);
      return system?.name ?? null;
    }

    return null;
  };
}
