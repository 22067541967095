﻿export function selectAndNumericCompare<T>(select: (value: T) => number | undefined) {
  return (a: T, b: T) => {
    const avalue = select(a);
    const bvalue = select(b);

    if (avalue === undefined && bvalue === undefined) {
      return 0;
    }

    if (avalue === undefined) {
      return -1;
    }

    if (bvalue === undefined) {
      return 1;
    }

    return avalue - bvalue;
  };
}
