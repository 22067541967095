import { useContracts } from '../../contracts/Contracts';
import { useThirdPartyLicensing } from './thirdPartyLicensing/ThirdPartyLicensing';
import { Responsible } from '../../../api';
import { getSystemForLicensing } from './helpers';

export function useGetSystemForLicensingFn() {
  const contracts = useContracts();
  const thirdPartyLicensing = useThirdPartyLicensing();
  return (reporting: Responsible, licensing: Responsible) => {
    return getSystemForLicensing(contracts.get, thirdPartyLicensing.get, reporting, licensing);
  };
}
