﻿import { AggregatedPackagingWeightsAustria } from '../../../../api';
import { weightFormat } from '../../../../helpers';
import { Badge } from 'react-bootstrap';
import { ReviewWeightsCell } from '../../ReviewWeightsCell';
import { PackagingKind } from './ResponsibilityKindNavItem';

export function ReviewWeightsRowCells(props: {
  household: boolean;
  commercial: boolean;
  weights: AggregatedPackagingWeightsAustria | null;
  comparison: AggregatedPackagingWeightsAustria | null;
}) {
  return (
    <>
      {props.household && (
        <>
          <ReviewWeightsCell weightKey="fiberHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="glassHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="ferrousMetalHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="aluminumHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="plasticHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell
            weightKey="beverageCartonHouseHold"
            weights={props.weights}
            comparison={props.comparison}
          />
          <ReviewWeightsCell weightKey="compositeHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="ceramicHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="woodHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="textileHouseHold" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="organicHouseHold" weights={props.weights} comparison={props.comparison} />
        </>
      )}
      {props.commercial && (
        <>
          <ReviewWeightsCell weightKey="fiberCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="ferrousMetalCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="aluminumCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="foilsCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="hollowBodiesCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="epsCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="woodCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="compositeCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="ceramicCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="textileCommercial" weights={props.weights} comparison={props.comparison} />
          <ReviewWeightsCell weightKey="organicCommercial" weights={props.weights} comparison={props.comparison} />
        </>
      )}
    </>
  );
}
