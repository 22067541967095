﻿import { PackagingWeights, Responsible } from '../../../../api';
import { SystemForLicensing } from '../helpers';
import { WeightsRowCells } from '../../../../WeightsRowCells';
import { SystemForLicensingInfo } from './SystemForLicensingInfo';
import { ResponsibleView } from '../../ResponsibleView';

export interface Props {
  producerId: string;
  system: SystemForLicensing;
  licensing: Responsible;
  weights: PackagingWeights;
}

export function ReportDetailPerLicenserRow({ producerId, licensing, system, weights }: Props) {
  return (
    <tr>
      <td className="align-middle">
        <ResponsibleView responsible={licensing} />
      </td>
      <td className="align-middle">
        <SystemForLicensingInfo producerId={producerId} licensing={licensing} system={system} count={1} />
      </td>
      <WeightsRowCells weights={weights} />
    </tr>
  );
}
