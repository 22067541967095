import { useTranslation } from 'react-i18next';
import { RouteObject, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useThirdPartyLicensing } from './ThirdPartyLicensing';
import { ThirdPartyLicensee } from '../../../../api';
import { usePeriodParam, useStringParams, useYearParam } from '../../../../shared/hooks/useParam';
import { ThirdPartyLicensingForm } from './ThirdPartyLicensingForm';
import { thirdPartyLicensingForm } from './form';
import { useForm } from '../../../../forms';
import { Icon } from '../../../../shared/components/Icon';
import { Modal } from '../../../../shared/components/Modal';
import { useProducers } from '../../../producers/Producers';
import { useSuppliers } from '../../../suppliers/suppliers';
import { expectNever } from '../../../../helpers';
import { getEmptyWeights } from '../getEmptyWeights';

// TODO: Add primary key to third_party_licensing table to get rid of this path
export const thirdPartyLicensingEditRoute: RouteObject = {
  path: 'edit/:producerId/:licenseeType/:licenseeId',
  element: <ThirdPartyLicensingEdit />,
};

function getLicenseeName(suppliers: Record<string, string>, licensee: ThirdPartyLicensee) {
  if (licensee.Case === 'Customer') {
    return licensee.Fields[0];
  }

  if (licensee.Case === 'Supplier') {
    return suppliers[licensee.Fields[0]] ?? licensee.Fields[0];
  }

  expectNever(licensee);
}

export function ThirdPartyLicensingEdit() {
  const { producerId, licenseeType, licenseeId } = useStringParams<'producerId' | 'licenseeType' | 'licenseeId'>();
  const year = useYearParam();
  const period = usePeriodParam();
  const { t } = useTranslation('reports');
  const producers = useProducers().all;
  const suppliers = useSuppliers();

  const licensee: ThirdPartyLicensee = {
    Case: licenseeType as ThirdPartyLicensee['Case'],
    Fields: [licenseeId],
  };

  const producerName = producers[producerId]?.name ?? producerId;
  const licenseeName = getLicenseeName(suppliers, licensee);
  const title = `${producerName} | ${licenseeName}`;

  const service = useThirdPartyLicensing();
  const thirdPartyLicensing = service.get(producerId, licensee);

  const initialValue = thirdPartyLicensing
    ? {
        ...thirdPartyLicensing,
        weights: thirdPartyLicensing.weights,
      }
    : {
        producerId: producerId,
        licensee: licensee,
        year: year,
        systemId: null,
        period: period,
        weights: null,
      };

  const form = useForm(thirdPartyLicensingForm, initialValue);

  const navigate = useNavigate();
  const [search] = useSearchParams();

  function onClose() {
    navigate({ pathname: '..', search: search.toString() });
  }

  const onSubmit = async () => {
    if (form.status.valid) {
      onClose();
      const { systemId, weights } = form.status.value;
      const w = weights !== null ? { ...getEmptyWeights(), ...weights } : null;
      await service.set(producerId, licensee, systemId, w);
    }
  };

  const onDelete = async (producerId: string, licensee: ThirdPartyLicensee) => {
    if (confirm(t('thirdPartyLicensingForm.confirmDeletion'))) {
      onClose();
      await service.remove(producerId, licensee);
    }
  };

  const footer = (
    <>
      <button className={'mx-2 btn btn-outline-danger'} type="button" onClick={() => onDelete(producerId, licensee)}>
        <Icon name="trash" /> {t('thirdPartyLicensingForm.delete')}
      </button>
      <button className={'btn btn-primary text-white'} type="submit" disabled={!form.status.valid} onClick={onSubmit}>
        {t('thirdPartyLicensingForm.save')}
      </button>
    </>
  );

  return (
    <Modal visible={true} title={title} onClose={onClose} footer={footer} size="lg">
      <ThirdPartyLicensingForm form={form} />
    </Modal>
  );
}
