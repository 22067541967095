﻿import { DeclarationResultGermany, PackagingWeights, Responsible } from '../../../../api';
import { Outlet, RouteObject, useOutletContext } from 'react-router';
import { SystemForLicensing } from '../helpers';
import { useProducers } from '../../../producers/Producers';
import { useCompareResponsible } from '../CompareResponsible';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { Icon } from '../../../../shared/components/Icon';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { selectAndLocaleCompare } from '../../selectAndLocaleCompare';
import { selectAndNumericCompare } from '../../selectAndNumericCompare';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { useReports } from '../../Reports';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { Trans, useTranslation } from 'react-i18next';
import { ContractEdit } from '../../../contracts/de/ContractEdit';
import { Actions } from '../Actions';
import { NavLink } from 'react-router-dom';
import { usePeriodParam, useStringParams, useYearParam } from '../../../../shared/hooks/useParam';
import { useProducersThatHaveToLicense } from './useProducersThatHaveToLicense';
import { ReportHistory } from '../ReportHistory';
import { AuditorExportButton } from '../export/AuditorExport';
import { LicenseDetailRow } from './LicenseDetailRow';
import { aggregate, LicenseAggregation } from './aggregate';
import { toArray } from 'ix/iterable';
import { useGetSystemNameFn } from '../UseGetSystemNameFn';
import { useGetSystemForLicensingFn } from '../UseGetSystemForLicensingFn';
import { getEmptyWeights } from '../getEmptyWeights';
import { SortableWeightsHeaderCells } from '../SortableWeightsHeaderCells';

export const licenseDetailRoute: RouteObject = {
  path: ':producerId',
  element: <LicenseDetail />,
  children: [
    {
      path: 'contracts/edit/:contractProducerId',
      element: <ContractEdit />,
    },
  ],
};

export function LicenseDetail() {
  const { t } = useTranslation('reports');

  const [period, year] = [usePeriodParam(), useYearParam()];
  const { producerId } = useStringParams<'producerId'>();

  const declaration = useOutletContext<DeclarationResultGermany>();

  const producers = useProducers().all;
  const producersThatHaveToLicense = useProducersThatHaveToLicense(period, declaration, true);
  const producer = producers[producerId];

  const aggregations = aggregate(useGetSystemForLicensingFn(), producerId, declaration.responsibilities);

  const getSystemName = useGetSystemNameFn();

  const reports = useReports();
  const report = reports.get(declaration.declarationId, producerId);

  const compareResponsible = useCompareResponsible();

  const emptyResponsible: Responsible = {
    Case: 'Producer',
    Fields: [producerId],
  };

  const emptySystem: SystemForLicensing = {
    type: 'SystemOfProducer',
    producerId: producerId,
  };

  const emptyWeights: PackagingWeights = getEmptyWeights();

  const sorting = useSorting<LicenseAggregation, 'reporting' | 'system' | PackagingWeightsKeys>(
    toArray(aggregations),
    {
      reporting: (a, b) => compareResponsible(a.reporting, b.reporting),
      system: selectAndLocaleCompare<LicenseAggregation>(r => getSystemName(r.system)),
      glass: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.glass),
      fiber: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.fiber),
      ferrousMetal: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.ferrousMetal),
      aluminum: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.aluminum),
      plastic: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.plastic),
      beverageCarton: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.beverageCarton),
      otherComposite: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.otherComposite),
      other: selectAndNumericCompare<LicenseAggregation>(r => r.weights?.other),
    },
    { key: 'system', direction: 'asc' }
  );

  return (
    <>
      {producersThatHaveToLicense.length > 1 ? (
        <div className="my-4">
          <NavLink className="btn btn-outline-secondary" to=".." end>
            <>
              <Icon name="chevron-left" />
              {t('reportStep.showAllDistributors')}
            </>
          </NavLink>
        </div>
      ) : (
        <Actions year={year} period={period} declaration={declaration} />
      )}
      <div className="my-3 d-flex align-items-center">
        <div style={{ width: '5rem', lineHeight: '4rem' }} className="text-center">
          <Icon name="building" className="fs-1" />
        </div>
        <div className="flex-grow-1">
          <h1 className="h2 my-0">{producer?.name ?? producerId}</h1>
          {producer && (
            <div className="text-muted">
              ID: {producer.id} | LUCID: {producer.lucidNumber}
            </div>
          )}
        </div>
      </div>
      <div className="bg-light rounded mb-4">
        <InfoScreen icon="send" color="primary" title={t('licenseDetail.reportInfoTitle')}>
          <p>
            <Trans t={t}>{t('licenseDetail.reportInfoText', { producer: producer?.name ?? producerId })}</Trans>
          </p>
          <div className="form-check form-switch d-inline-block">
            <input
              className="form-check-input"
              id="cb-report"
              type="checkbox"
              checked={!!report?.proReportedAt}
              onChange={e => reports.report(declaration.declarationId, producerId, 'Pro', e.target.checked)}
            />
            <label className="form-check-label" htmlFor="cb-report">
              {t('licenseDetail.markAsReported')}
            </label>
          </div>
          <div className="mt-2">
            <ReportHistory
              producerId={producerId}
              type="Pro"
              renderDownloadButton={declarationId => <AuditorExportButton declarationId={declarationId} />}
            />
          </div>
        </InfoScreen>
      </div>
      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <SortableHeaderCell sorting={sorting} sortKey="system">
              {t('licenseDetail.systemProvider')}
            </SortableHeaderCell>
            <SortableHeaderCell sorting={sorting} sortKey="reporting">
              {t('licenseDetail.distributedBy')}
            </SortableHeaderCell>
            <SortableWeightsHeaderCells sorting={sorting} />
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.length > 0 ? (
            sorting.sorted.map(a => (
              <LicenseDetailRow
                key={a.key}
                system={a.system}
                reporting={a.reporting}
                producerId={producerId}
                weights={a.weights ?? emptyWeights}
              />
            ))
          ) : (
            <LicenseDetailRow
              system={emptySystem}
              reporting={emptyResponsible}
              producerId={producerId}
              weights={emptyWeights}
            />
          )}
        </tbody>
      </table>
      <Outlet />
    </>
  );
}
