import { useTranslation } from 'react-i18next';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { useCompareResponsible } from '../CompareResponsible';
import { selectAndNumericCompare } from '../../selectAndNumericCompare';
import { ReviewAggregation } from './aggregate';
import { IterableX, toArray } from 'ix/iterable';
import { ReviewListRow } from './ReviewListRow';
import { SortableWeightsHeaderCells } from '../SortableWeightsHeaderCells';

export function ReviewList({ data }: { data: IterableX<ReviewAggregation> }) {
  const { t } = useTranslation('reports');

  const compareResponsible = useCompareResponsible();

  function compareKey(a: ReviewAggregation, b: ReviewAggregation) {
    return compareResponsible(a.responsible, b.responsible);
  }

  const sorting = useSorting<ReviewAggregation, 'key' | PackagingWeightsKeys>(
    toArray(data),
    {
      key: compareKey,
      glass: selectAndNumericCompare(a => a.weights.current?.glass),
      fiber: selectAndNumericCompare(a => a.weights.current?.fiber),
      ferrousMetal: selectAndNumericCompare(a => a.weights.current?.ferrousMetal),
      aluminum: selectAndNumericCompare(a => a.weights.current?.aluminum),
      plastic: selectAndNumericCompare(a => a.weights.current?.plastic),
      beverageCarton: selectAndNumericCompare(a => a.weights.current?.beverageCarton),
      otherComposite: selectAndNumericCompare(a => a.weights.current?.otherComposite),
      other: selectAndNumericCompare(a => a.weights.current?.other),
    },
    { key: 'key', direction: 'asc' }
  );

  return (
    <table className="table table-hover">
      <thead className="sticky-top bg-white">
        <tr>
          <th style={{ width: '1%' }}></th>
          <SortableHeaderCell sorting={sorting} sortKey="key">
            {t('reviewStep.company')}
          </SortableHeaderCell>
          <th></th>
          <SortableWeightsHeaderCells sorting={sorting} />
        </tr>
      </thead>
      <tbody>
        {sorting.sorted.map(a => (
          <ReviewListRow key={a.key} a={a} />
        ))}
      </tbody>
    </table>
  );
}
