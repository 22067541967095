﻿import { PackagingWeights } from '../../../../api';
import { useProducers } from '../../../producers/Producers';
import { SystemForLicensing } from '../helpers';
import { ReportDetailPerSystemRow } from './ReportDetailPerSystemRow';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { selectAndLocaleCompare } from '../../selectAndLocaleCompare';
import { selectAndNumericCompare } from '../../selectAndNumericCompare';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { useReports } from '../../Reports';
import { Trans, useTranslation } from 'react-i18next';
import { ReportHistory } from '../ReportHistory';
import { AuditorExportButton } from '../export/AuditorExport';
import { useGetSystemNameFn } from '../UseGetSystemNameFn';
import { aggregatePerSystem, ReportingAggregation, ReportingPerSystemAggregation } from './aggregate';
import { IterableX, toArray } from 'ix/iterable';
import { getEmptyWeights } from '../getEmptyWeights';
import { SortableWeightsHeaderCells } from '../SortableWeightsHeaderCells';

export interface Props {
  declarationId: number;
  producerId: string;
  aggregations: IterableX<ReportingAggregation>;
}

export function ReportDetailPerSystem({ declarationId, aggregations, producerId }: Props) {
  const { t } = useTranslation('reports');
  const producers = useProducers();
  const producer = producers.get(producerId);

  const getSystemName = useGetSystemNameFn();

  const emptySystem: SystemForLicensing = {
    type: 'SystemOfProducer',
    producerId: producerId,
  };

  const emptyWeights: PackagingWeights = getEmptyWeights();

  const aggregationsPerSystem = aggregatePerSystem(aggregations);

  const sorting = useSorting<ReportingPerSystemAggregation, 'system' | PackagingWeightsKeys>(
    toArray(aggregationsPerSystem),
    {
      system: selectAndLocaleCompare<ReportingPerSystemAggregation>(r => getSystemName(r.system)),
      glass: selectAndNumericCompare<ReportingPerSystemAggregation>(r => r.weights.glass),
      fiber: selectAndNumericCompare<ReportingPerSystemAggregation>(r => r.weights.fiber),
      ferrousMetal: selectAndNumericCompare<ReportingPerSystemAggregation>(r => r.weights.ferrousMetal),
      aluminum: selectAndNumericCompare<ReportingPerSystemAggregation>(r => r.weights.aluminum),
      plastic: selectAndNumericCompare<ReportingPerSystemAggregation>(r => r.weights.plastic),
      beverageCarton: selectAndNumericCompare<ReportingPerSystemAggregation>(r => r.weights.beverageCarton),
      otherComposite: selectAndNumericCompare<ReportingPerSystemAggregation>(r => r.weights.otherComposite),
      other: selectAndNumericCompare<ReportingPerSystemAggregation>(r => r.weights.other),
    },
    { key: 'system', direction: 'asc' }
  );

  const reports = useReports();
  const report = reports.get(declarationId, producerId);

  return (
    <>
      <div className="bg-light rounded mb-4">
        <InfoScreen icon="send" color="primary" title={t('reportDetailPerSystem.title')}>
          <p>
            <Trans t={t}>{t('reportDetailPerSystem.subtitle', { producer: producer?.name ?? producerId })}</Trans>
          </p>
          <div className="form-check form-switch d-inline-block">
            <input
              className="form-check-input"
              id="cb-report"
              type="checkbox"
              checked={!!report?.lucidReportedAt}
              onChange={e => reports.report(declarationId, producerId, 'Lucid', e.target.checked)}
            />
            <label className="form-check-label" htmlFor="cb-report">
              {t('reportDetailPerSystem.markAsReported')}
            </label>
          </div>
          <div className="mt-2">
            <ReportHistory
              producerId={producerId}
              type="Lucid"
              renderDownloadButton={declarationId => <AuditorExportButton declarationId={declarationId} />}
            />
          </div>
        </InfoScreen>
      </div>
      <table className="table table-hover  table-striped">
        <thead className="sticky-top bg-white">
          <tr>
            <SortableHeaderCell sorting={sorting} sortKey="system">
              {t('reportDetailPerSystem.systemProvider')}
            </SortableHeaderCell>
            <SortableWeightsHeaderCells sorting={sorting} />
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.length > 0 ? (
            sorting.sorted.map(a => (
              <ReportDetailPerSystemRow
                producerId={producerId}
                key={a.key}
                system={a.system}
                count={a.count}
                weights={a.weights}
              />
            ))
          ) : (
            <ReportDetailPerSystemRow producerId={producerId} system={emptySystem} count={0} weights={emptyWeights} />
          )}
        </tbody>
      </table>
    </>
  );
}
