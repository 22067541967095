import {
  AggregatedPackagingWeightsAustria,
  Responsible,
  ResponsibilityAustria,
  ResponsibilityPeriod,
} from '../../../../api';
import { from, first, IterableX, reduce } from 'ix/iterable';
import { groupBy, map, memoize, orderBy } from 'ix/iterable/operators';
import { serializeKey } from '../../../../serializeKey';
import { addWeights } from '../addWeights';

export type CombinedResponsibility = ResponsibilityAustria & { comparison?: true };

export interface AggregatedWeights {
  forecast: boolean;
  current: AggregatedPackagingWeightsAustria | null;
  comparison: AggregatedPackagingWeightsAustria | null;
}

export type ReviewAggregation = {
  key: string;
  responsible: Responsible;
  months: IterableX<{ month: number | null; weights: AggregatedWeights }>;
  weights: AggregatedWeights;
};

const emptyAggregateWeights: AggregatedWeights = {
  forecast: false,
  current: null,
  comparison: null,
};

export function aggregate(responsibilities: CombinedResponsibility[]): IterableX<ReviewAggregation> {
  return from(responsibilities).pipe(
    groupBy(r => serializeKey(r.licensing)),
    map(g => {
      const months = g.pipe(
        groupBy(r => serializeKey(r.month)),
        map(g => ({
          month: first(g)!.month,
          weights: reduce(
            g,
            (a, b) => ({
              forecast: b.comparison ? a.forecast : a.forecast || b.forecast,
              current: b.comparison ? a.current : addWeights(a.current, b.weights),
              comparison: b.comparison ? addWeights(a.comparison, b.weights) : a.comparison,
            }),
            emptyAggregateWeights
          ),
        })),
        orderBy(a => a.month),
        memoize()
      );

      return {
        key: g.key,
        responsible: first(g)!.licensing,
        months: months,
        weights: reduce(
          months,
          (a, b) => ({
            forecast: a.forecast || b.weights.forecast,
            current: addWeights(a.current, b.weights.current),
            comparison: addWeights(a.comparison, b.weights.comparison),
          }),
          emptyAggregateWeights
        ),
      };
    }),
    memoize()
  );
}
