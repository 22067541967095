import { PackagingWeights } from '../../../api';

export function addWeights(a: PackagingWeights | null, b: PackagingWeights | null): PackagingWeights | null {
  if (a === null && b === null) {
    return null;
  }

  return {
    glass: (a?.glass ?? 0) + (b?.glass ?? 0),
    fiber: (a?.fiber ?? 0) + (b?.fiber ?? 0),
    ferrousMetal: (a?.ferrousMetal ?? 0) + (b?.ferrousMetal ?? 0),
    aluminum: (a?.aluminum ?? 0) + (b?.aluminum ?? 0),
    plastic: (a?.plastic ?? 0) + (b?.plastic ?? 0),
    beverageCarton: (a?.beverageCarton ?? 0) + (b?.beverageCarton ?? 0),
    otherComposite: (a?.otherComposite ?? 0) + (b?.otherComposite ?? 0),
    other: (a?.other ?? 0) + (b?.other ?? 0),
  };
}
