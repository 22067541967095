import { ResponsibilityAustria } from '../../../api';
import { expectNever } from '../../../helpers';

export function tryGetIdOfProducerThatHasToLicense(responsibility: ResponsibilityAustria) {
  if (responsibility.licensing === null) {
    return null;
  }

  if (responsibility.licensing === 'Customers') {
    return null;
  }

  if (responsibility.licensing.Case === 'Supplier') {
    return null;
  }

  if (responsibility.licensing.Case === 'Customer') {
    return null;
  }

  if (responsibility.licensing.Case === 'Producer') {
    return responsibility.licensing.Fields[0];
  }

  expectNever(responsibility.licensing);
}
