import { DeclarationInterval, Period } from '../../../api';

const monthToPeriod = (month: number): Period | null => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
      return null;
  }
};

const monthToQuarter = (month: number): Period | null => {
  if (month >= 0 && month <= 2) {
    return 'Q1';
  }
  if (month >= 3 && month <= 5) {
    return 'Q2';
  }
  if (month >= 6 && month <= 8) {
    return 'Q3';
  }
  if (month >= 9 && month <= 11) {
    return 'Q4';
  }
  return null;
};

const monthToHalf = (month: number): Period | null => {
  if (month >= 0 && month <= 5) {
    return 'FirstHalfYear';
  }
  if (month >= 6 && month <= 11) {
    return 'SecondHalfYear';
  }
  return null;
};

export function getInitialPeriod(year: number, intervals: DeclarationInterval[]): Period {
  const now = new Date();

  if (intervals.includes('Monthly')) {
    if (year < now.getFullYear()) {
      return 'Dec';
    } else {
      return monthToPeriod(now.getMonth()) ?? 'Year';
    }
  }

  if (intervals.includes('Quarterly')) {
    if (year < now.getFullYear()) {
      return 'Q4';
    } else {
      return monthToQuarter(now.getMonth()) ?? 'Year';
    }
  }

  if (intervals.includes('BiYearly')) {
    if (year < now.getFullYear()) {
      return 'SecondHalfYear';
    } else {
      return monthToHalf(now.getMonth()) ?? 'Year';
    }
  }

  return 'Year';
}
