import { DeclarationInterval, PackagingType, Period } from './api';
import i18n from './i18n';

export function URI(literals: TemplateStringsArray, ...values: (number | boolean | string)[]) {
  let result = '';
  const length = Math.max(literals.length, values.length);
  for (let i = 0; i < length; i++) {
    result += literals[i];
    const value = values[i];
    if (value !== undefined) {
      result += encodeURIComponent(values[i].toString());
    }
  }
  return result;
}

export function serializeQuery(params: any, prefix?: string) {
  const query: any = Object.keys(params).map(key => {
    const value = params[key];
    if (value === undefined || value === null) {
      return '';
    }

    if (params.constructor === Array) {
      key = `${prefix}`;
    } else if (params.constructor === Object) {
      key = prefix ? `${prefix}.${key}` : key;
    }

    if (typeof value === 'object') {
      return serializeQuery(value, key);
    } else {
      if (typeof value !== 'string' || value.length > 0) {
        return `${key}=${encodeURIComponent(value)}`;
      }
    }

    return undefined;
  });

  return [...query].filter((q: any) => q !== undefined && q !== null && q !== '').join('&');
}

/**
 * Takes a function that returns a promise and returns a function that returns a promise.
 * But when the returned function is called multiple times and the promise of a previous call is still pending it just returns the pending promise.
 * @param func The function.
 */
export function singleCall<T>(func: () => Promise<T>) {
  let promise: Promise<T> | null = null;
  return () => {
    return promise != null ? promise : (promise = func().finally(() => (promise = null)));
  };
}

function hasObjectPrototype(o: any): boolean {
  return Object.prototype.toString.call(o) === '[object Object]';
}

// Copied from: https://github.com/jonschlinkert/is-plain-object
export function isPlainObject(o: any): o is Record<any, any> {
  if (!hasObjectPrototype(o)) {
    return false;
  }

  // If has modified constructor
  const ctor = o.constructor;
  if (typeof ctor === 'undefined') {
    return true;
  }

  // If has modified prototype
  const prot = ctor.prototype;
  if (!hasObjectPrototype(prot)) {
    return false;
  }

  // If constructor does not have an Object-specific method
  if (!('isPrototypeOf' in prot)) {
    return false;
  }

  // Most likely a plain Object
  return true;
}

/**
 * Hashes the value into a stable hash.
 */
export function getHash(value: unknown): string {
  if (value === undefined || value === null) {
    return 'null';
  }

  return JSON.stringify(value, (_, val) =>
    isPlainObject(val)
      ? Object.keys(val)
          .sort()
          .reduce((result, key) => {
            result[key] = val[key];
            return result;
          }, {} as any)
      : val
  );
}

export interface Resource<T> {
  read(): T;
}

export function createResource<T>(promiseOrFactory: Promise<T> | (() => Promise<T>)): Resource<T> {
  let state: 'pending' | 'resolved' | 'rejected' = 'pending';
  let result: unknown = null;

  let suspender: Promise<void> | null = null;

  function ensureSuspender() {
    if (suspender !== null) {
      return;
    }

    suspender = (typeof promiseOrFactory === 'function' ? promiseOrFactory() : promiseOrFactory).then(
      data => {
        state = 'resolved';
        result = data;
      },
      error => {
        state = 'rejected';
        result = error;
      }
    );
  }

  return {
    read() {
      ensureSuspender();

      switch (state) {
        case 'pending':
          throw suspender;
        case 'rejected':
          throw result;
        case 'resolved':
          return result as T;
      }
    },
  };
}

export function joinOrNull(separator: string, ...values: (string | null)[]) {
  const nonNullValues = values.filter(v => v !== null);
  return nonNullValues.length === 0 ? null : nonNullValues.join(separator);
}

export type PromiseState<t> =
  | { resolved: true; rejected: false; aborted: false; data: t }
  | { resolved: false; rejected: true; aborted: false; error: unknown }
  | { resolved: false; rejected: false; aborted: true; error: Error };

export interface SuspendedPromise<T> {
  read(): PromiseState<T>;
}

/**
 * Returns an object with a read function which uses Reacts Suspense mechanism.
 * @param promise The promise used to suspend.
 */
export function suspendPromise<T>(promise: Promise<T>): SuspendedPromise<T> {
  let state: PromiseState<T> | null = null;
  const suspender = promise.then(
    data => {
      state = { resolved: true, rejected: false, aborted: false, data };
    },
    error => {
      state =
        error instanceof Error && error.name === 'AbortError'
          ? { resolved: false, rejected: false, aborted: true, error }
          : { resolved: false, rejected: true, aborted: false, error };
    }
  );
  return {
    read() {
      if (state == null) {
        throw suspender;
      } else {
        return state;
      }
    },
  };
}

/**
 * Expects the specified value to be never.
 * This can be used to do exhaustiveness checks.
 * At compile time, there is an compiler error if the specified parameter is not of type never.
 * At runtime, an error is thrown.
 * @param value The value that is expected to be of type never.
 */
export function expectNever(value: never): never {
  throw new Error('Expected to be never called, but got: ' + (value as any).toString());
}

function quoteIfNeeded(value: string | null) {
  if (value === null) {
    return null;
  }

  if (value.search(/(;|\n|\r)/) > -1) {
    return '"' + value + '"';
  }

  return value;
}

/* Exports data as a UTF8 encoding CSV file with a header. */
export function exportAsCsvUtf8(fileName: string, header: string[], rows: (string | null)[][]) {
  const content =
    'data:text/csv;charset=utf-8,' + [header, ...rows].map(e => e.map(quoteIfNeeded).join(';')).join('\n');
  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function createEncodedFilename(name: string, extension: string) {
  return name.replace(/[^a-z0-9_-]/gi, '-') + extension;
}

export const weightFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  useGrouping: false,
});

export const weightDiffFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  useGrouping: false,
  signDisplay: 'exceptZero',
});

export const currencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
  useGrouping: false,
});

export function getNameOfPeriod(period: Period) {
  return i18n.t(`global:periods.${period}`);
}

export function getPeriodShortName(period: Period) {
  return i18n.t(`global:shortPeriod.${period}`);
}

export function getNameOfPackagingType(packagingType: PackagingType) {
  const t = i18n.t;

  if (packagingType === 'Sale') {
    return t('global:packagingTypes.salesPackaging');
  }

  if (packagingType === 'Service') {
    return t('global:packagingTypes.servicePackaging');
  }

  if (packagingType === 'Outer') {
    return t('global:packagingTypes.outerPackaging');
  }

  if (packagingType === 'Shipping') {
    return t('global:packagingTypes.shippingPackaging');
  }

  if (packagingType === 'Transport') {
    return t('global:packagingTypes.transportPackaging');
  }

  expectNever(packagingType);
}

export function formatCurrency(value: number, language?: string) {
  return Intl.NumberFormat(language || 'de-DE', { style: 'currency', currency: 'EUR', useGrouping: true }).format(
    value
  );
}

export function getIndexOfInterval(interval: DeclarationInterval) {
  switch (interval) {
    case 'Monthly':
      return 0;
    case 'Quarterly':
      return 1;
    case 'BiYearly':
      return 2;
    case 'Yearly':
      return 3;
  }
  expectNever(interval);
}

export function getNameOfInterval(interval: DeclarationInterval) {
  const t = i18n.t;

  switch (interval) {
    case 'Monthly':
      return t('global:declarationIntervals.monthly');
    case 'Quarterly':
      return t('global:declarationIntervals.quarterly');
    case 'BiYearly':
      return t('global:declarationIntervals.biYearly');
    case 'Yearly':
      return t('global:declarationIntervals.yearly');
  }
  expectNever(interval);
}

const periodMapping: Record<Period, Period[]> = {
  InitialForecastYear: [],
  ForecastYear: ['FirstHalfYear', 'SecondHalfYear'],
  Year: ['FirstHalfYear', 'SecondHalfYear'],
  FirstHalfYear: ['Q1', 'Q2'],
  SecondHalfYear: ['Q3', 'Q4'],
  Q1: ['Jan', 'Feb', 'Mar'],
  Q2: ['Apr', 'May', 'Jun'],
  Q3: ['Jul', 'Aug', 'Sep'],
  Q4: ['Oct', 'Nov', 'Dec'],
  Jan: [],
  Feb: [],
  Mar: [],
  Apr: [],
  May: [],
  Jun: [],
  Jul: [],
  Aug: [],
  Sep: [],
  Oct: [],
  Nov: [],
  Dec: [],
};

/**
 * Checks is one period contains the other.
 */
export function getPeriodContains(periodOfDeclaration: Period, periodOfPlacing: Period) {
  if (periodOfDeclaration === periodOfPlacing) {
    return true;
  }

  const children = periodMapping[periodOfDeclaration];

  if (children.includes(periodOfPlacing)) {
    return true;
  }

  for (const child of children) {
    if (getPeriodContains(child, periodOfPlacing)) {
      return true;
    }
  }

  return false;
}

/**
 * Returns the fraction of one period to the other.
 */
export function getPeriodFraction(periodOfDeclaration: Period, periodOfPlacing: Period): number {
  const children = periodMapping[periodOfPlacing];

  if (children.includes(periodOfDeclaration)) {
    return 1 / children.length;
  }

  for (const child of children) {
    const f = getPeriodFraction(periodOfDeclaration, child);
    if (f !== 0) {
      return (1 / children.length) * f;
    }
  }

  return 0;
}

/**
 * Returns the fraction that has to be applied to the weights of a placing.
 */
export function getFractionForPlacing(periodOfDeclaration: Period, periodOfPlacing: Period): number {
  // same
  if (periodOfDeclaration === periodOfPlacing) {
    return 1;
  }

  // contains
  if (getPeriodContains(periodOfDeclaration, periodOfPlacing)) {
    return 1;
  }

  // fraction
  return getPeriodFraction(periodOfDeclaration, periodOfPlacing);
}

/**
 * Returns true if the value is not null or undefined, otherwise false.
 */
export function notNullOrUndefined<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
