﻿import { createDeclaration, getAvailability, getPeriods, Period, tryGetDeclarationAustria } from '../../../../api';
import { useTenantMutation } from '../../../../remote';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { Icon } from '../../../../shared/components/Icon';
import { useTranslation } from 'react-i18next';
import { useTenant } from '../../../tenants/TenantDetail';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { AvailabilityView } from '../../AvailabilityView';
import { useQueryState } from '../../../../shared/hooks/useQueryState';

export interface Props {
  year: number;
  period: Period;
}

export function NotYetCreatedScreen({ year, period }: Props) {
  const { t } = useTranslation('reports');

  const periodsQuery = useTenantQuery(getPeriods, { country: 'Austria', year }, false);

  const declarationQuery = useTenantQuery(tryGetDeclarationAustria, { year, period }, false);

  const tenant = useTenant();

  const [availability] = useQueryState(useTenantQuery(getAvailability, [year, period]));

  const mutation = useTenantMutation(createDeclaration);

  function onClick() {
    mutation.start(
      {
        country: 'Austria',
        year,
        period,
        months: availability.filter(a => a.availability === 'Available').map(a => a.month),
      },
      {
        async onSuccess() {
          declarationQuery.setValue('Running');
          await Promise.all([periodsQuery.revalidate(), declarationQuery.revalidate()]);
        },
      }
    );
  }

  return (
    <InfoScreen title={t('notYetCreatedScreen.title')} icon="info-circle" color="primary">
      <p>{t('notYetCreatedScreen.hint')}</p>
      <br />
      {tenant.permissions.central && <AvailabilityView year={year} period={period} availability={availability} />}
      <div className="mt-2">
        <button disabled={mutation.isPending} className="btn btn-primary text-white mt-4" onClick={onClick}>
          <Icon name="calculator" /> {t('notYetCreatedScreen.calculate')}
        </button>
      </div>
    </InfoScreen>
  );
}
