﻿import { useTranslation } from 'react-i18next';
import { SortableHeaderCell } from '../../../shared/components/SortableHeaderCell';
import { Sorting } from '../../../shared/hooks/useSorting';
import { PackagingKind } from './review/ResponsibilityKindNavItem';

export interface Props {
  household: boolean;
  commercial: boolean;
  sorting: Sorting<any, any>;
}

export function SortableWeightsHeaderCells({ household, commercial, sorting }: Props) {
  const { t } = useTranslation('global');

  return (
    <>
      {household && (
        <>
          <SortableHeaderCell sorting={sorting} sortKey="fiberHouseHold" className="col-1 text-end">
            {t('global:fractions.at.fiberHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="glassHouseHold" className="col-1 text-end">
            {t('global:fractions.at.glassHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="ferrousMetalHouseHold" className="col-1 text-end">
            {t('global:fractions.at.ferrousMetalHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="aluminumHouseHold" className="col-1 text-end">
            {t('global:fractions.at.aluminumHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="plasticHouseHold" className="col-1 text-end">
            {t('global:fractions.at.plasticHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="beverageCartonHouseHold" className="col-1 text-end">
            {t('global:fractions.at.beverageCartonHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="compositeHouseHold" className="col-1 text-end">
            {t('global:fractions.at.compositeHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="ceramicHouseHold" className="col-1 text-end">
            {t('global:fractions.at.ceramicHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="woodHouseHold" className="col-1 text-end">
            {t('global:fractions.at.woodHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="textileHouseHold" className="col-1 text-end">
            {t('global:fractions.at.textileHouseHold')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="organicHouseHold" className="col-1 text-end">
            {t('global:fractions.at.organicHouseHold')}
          </SortableHeaderCell>
        </>
      )}
      {commercial && (
        <>
          <SortableHeaderCell sorting={sorting} sortKey="fiberCommercial" className="col-1 text-end">
            {t('global:fractions.at.fiberCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="ferrousMetalCommercial" className="col-1 text-end">
            {t('global:fractions.at.ferrousMetalCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="aluminumCommercial" className="col-1 text-end">
            {t('global:fractions.at.aluminumCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="foilsCommercial" className="col-1 text-end">
            {t('global:fractions.at.foilsCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="hollowBodiesCommercial" className="col-1 text-end">
            {t('global:fractions.at.hollowBodiesCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="epsCommercial" className="col-1 text-end">
            {t('global:fractions.at.epsCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="woodCommercial" className="col-1 text-end">
            {t('global:fractions.at.woodCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="compositeCommercial" className="col-1 text-end">
            {t('global:fractions.at.compositeCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="ceramicCommercial" className="col-1 text-end">
            {t('global:fractions.at.ceramicCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="textileCommercial" className="col-1 text-end">
            {t('global:fractions.at.textileCommercial')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="organicCommercial" className="col-1 text-end">
            {t('global:fractions.at.organicCommercial')}
          </SortableHeaderCell>
        </>
      )}
    </>
  );
}
