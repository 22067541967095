import {
  AggregatedPackagingWeightsAustria,
  Responsible,
  ResponsibilityAustria,
  ResponsibilityPeriod,
} from '../../../../api';
import { from, first, IterableX, reduce } from 'ix/iterable';
import { filter, groupBy, map, memoize } from 'ix/iterable/operators';
import { serializeKey } from '../../../../serializeKey';
import { isProducer } from '../../de/helpers';
import { addWeights } from '../addWeights';

export type LicenseAggregation = {
  key: string;
  responsible: Responsible;
  weights: AggregatedPackagingWeightsAustria | null;
};

export function aggregate(
  producerId: string,
  responsibilities: ResponsibilityAustria[]
): IterableX<LicenseAggregation> {
  return from(responsibilities).pipe(
    filter(r => isProducer(r.licensing) && r.licensing.Fields[0] === producerId),
    groupBy(r => serializeKey(r.licensing)),
    map(g => ({
      key: g.key,
      responsible: first(g)!.licensing,
      weights: reduce(g, (a, c) => addWeights(a, c.weights), null as AggregatedPackagingWeightsAustria | null),
    })),
    memoize()
  );
}
