import { PackagingKind } from './ResponsibilityKindNavItem';
import { ReviewAggregation } from './aggregate';
import { ResponsibleView } from '../../ResponsibleView';
import { ReviewWeightsRowCells } from './ReviewWeightsRowCells';
import { useState } from 'react';
import { Icon } from '../../../../shared/components/Icon';
import { ForecastCell } from '../../ForecastCell';
import { map } from 'ix/iterable/operators';
import { serializeKey } from '../../../../serializeKey';
import { formatResponsibilityPeriod } from '../../de/review/formatResponsibilityPeriod';
import { useTranslation } from 'react-i18next';

export function ReviewListRow({ kind, aggregation }: { kind: PackagingKind; aggregation: ReviewAggregation }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <tr style={{ cursor: 'pointer' }} onClick={() => setExpanded(e => !e)}>
        <td className={`align-middle`}>
          <button className="bg-transparent border-0 text-primary">
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </button>
        </td>
        <td className="align-middle">
          <ResponsibleView responsible={aggregation.responsible} />
        </td>
        <ForecastCell forecast={aggregation.weights.forecast} />
        <ReviewWeightsRowCells
          household={kind === 'household'}
          commercial={kind === 'commercial'}
          weights={aggregation.weights.current}
          comparison={aggregation.weights.comparison}
        />
      </tr>
      {expanded && (
        <>
          {aggregation.months.pipe(
            map(m => (
              <tr key={serializeKey(m.month)} className="table-light">
                <td className="border-start"></td>
                <td className="px-4">
                  {formatResponsibilityPeriod(
                    t,
                    m.month === null ? 'Unspecified' : { Case: 'Month', Fields: [m.month] }
                  )}
                </td>
                <ForecastCell forecast={m.weights.forecast} />
                <ReviewWeightsRowCells
                  household={kind === 'household'}
                  commercial={kind === 'commercial'}
                  weights={m.weights.current}
                  comparison={m.weights.comparison}
                />
              </tr>
            ))
          )}
          <tr>
            <td colSpan={10}></td>
          </tr>
        </>
      )}
    </>
  );
}
