import { AggregatedPackagingWeightsAustria } from '../../../api';

export function addWeights(
  a: AggregatedPackagingWeightsAustria | null,
  b: AggregatedPackagingWeightsAustria | null
): AggregatedPackagingWeightsAustria | null {
  if (a === null) {
    return b;
  }

  if (b === null) {
    return a;
  }

  return {
    aluminumCommercial: a.aluminumCommercial + b.aluminumCommercial,
    aluminumHouseHold: a.aluminumHouseHold + b.aluminumHouseHold,
    beverageCartonHouseHold: a.beverageCartonHouseHold + b.beverageCartonHouseHold,
    ceramicCommercial: a.ceramicCommercial + b.ceramicCommercial,
    ceramicHouseHold: a.ceramicHouseHold + b.ceramicHouseHold,
    compositeCommercial: a.compositeCommercial + b.compositeCommercial,
    compositeHouseHold: a.compositeHouseHold + b.compositeHouseHold,
    epsCommercial: a.epsCommercial + b.epsCommercial,
    ferrousMetalCommercial: a.ferrousMetalCommercial + b.ferrousMetalCommercial,
    ferrousMetalHouseHold: a.ferrousMetalHouseHold + b.ferrousMetalHouseHold,
    fiberCommercial: a.fiberCommercial + b.fiberCommercial,
    fiberHouseHold: a.fiberHouseHold + b.fiberHouseHold,
    foilsCommercial: a.foilsCommercial + b.foilsCommercial,
    glassHouseHold: a.glassHouseHold + b.glassHouseHold,
    hollowBodiesCommercial: a.hollowBodiesCommercial + b.hollowBodiesCommercial,
    organicCommercial: a.organicCommercial + b.organicCommercial,
    organicHouseHold: a.organicHouseHold + b.organicHouseHold,
    plasticHouseHold: a.plasticHouseHold + b.plasticHouseHold,
    textileCommercial: a.textileCommercial + b.textileCommercial,
    textileHouseHold: a.textileHouseHold + b.textileHouseHold,
    woodCommercial: a.woodCommercial + b.woodCommercial,
    woodHouseHold: a.woodHouseHold + b.woodHouseHold,
  };
}
