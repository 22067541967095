﻿import {
  ContractValue,
  DeclarationInterval,
  PackagingWeights,
  Period,
  Responsibility,
  Responsible,
  ThirdPartyLicensee,
  ThirdPartyLicensing,
} from '../../../api';
import { expectNever } from '../../../helpers';
import { serializeKey } from '../../../serializeKey';
import { addWeights } from './addWeights';

export interface AggregationWithThirdPartyLicensing<K> {
  key: K;
  keyAsString: string;
  producer: string;
  licensee: string;
  licenseeType: 'Customer' | 'Supplier';
  weights: PackagingWeights | null;
  thirdPartyLicensing: ThirdPartyLicensing | null;
}

export function filterAndAggregateWeightsForThirdPartyLicensing<K>(
  getKey: (row: Responsibility) => K,
  rows: Responsibility[]
): AggregationWithThirdPartyLicensing<K>[] {
  const result: Record<string, AggregationWithThirdPartyLicensing<K>> = {};

  for (const row of rows) {
    const key = getKey(row);

    if (key === null || row.licensing === null || row.licensing === 'Customers' || row.licensing.Case === 'Producer') {
      continue;
    }

    const keyAsString = serializeKey(key);

    if (Object.hasOwn(result, keyAsString)) {
      const aggregation = result[keyAsString];

      if (row.licensing.Case === 'Supplier') {
        aggregation.licenseeType = 'Supplier';
        aggregation.licensee = row.licensing.Fields[0];
      }

      if (row.licensing.Case === 'Customer') {
        aggregation.licenseeType = 'Customer';
        aggregation.licensee = row.licensing.Fields[0];
      }

      aggregation.weights = addWeights(aggregation.weights, row.weights);
    } else {
      if (row.reporting === null || row.reporting === 'Customers' || row.reporting.Case !== 'Producer') {
        continue;
      }

      result[keyAsString] = {
        key,
        keyAsString,
        producer: row.reporting.Fields[0],
        licenseeType: row.licensing.Case,
        licensee: row.licensing.Fields[0],
        weights: row.weights,
        thirdPartyLicensing: null,
      };
    }
  }

  return Object.values(result);
}

export function producerHasToReport(producerId: string) {
  return (row: Responsibility) =>
    typeof row.reporting === 'object' && row.reporting?.Case === 'Producer' && row.reporting.Fields[0] === producerId;
}

export function producerHasToLicense(producerId: string) {
  return (row: Responsibility) =>
    typeof row.licensing === 'object' && row.licensing?.Case === 'Producer' && row.licensing.Fields[0] === producerId;
}

export function producerHasToPay(producerId: string) {
  return (row: Responsibility) =>
    typeof row.payment === 'object' && row.payment?.Case === 'Producer' && row.payment.Fields[0] === producerId;
}

export type SystemForLicensing =
  | { type: 'SystemOfProducer'; producerId: string }
  | { type: 'SystemOfSupplier'; supplierId: string }
  | { type: 'SystemOfCustomer'; customerId: string }
  | { type: 'SystemsOfCustomers' }
  | { type: 'System'; systemId: number };

export function getSystemForLicensing(
  tryGetContract: (producerId: string) => ContractValue | null,
  getSystemForThirdPartyLicensing: (producerId: string, licensee: ThirdPartyLicensee) => ThirdPartyLicensing | null,
  reporting: Responsible,
  licensing: Responsible
): SystemForLicensing {
  if (licensing === 'Customers') {
    return { type: 'SystemsOfCustomers' };
  }

  if (licensing.Case === 'Producer') {
    const systemId = tryGetContract(licensing.Fields[0])?.systemId ?? null;
    return systemId === null
      ? { type: 'SystemOfProducer', producerId: licensing.Fields[0] }
      : { type: 'System', systemId };
  }

  if (licensing.Case === 'Supplier') {
    if (typeof reporting === 'object' && reporting.Case === 'Producer') {
      const thirdPartyLicensing = getSystemForThirdPartyLicensing(reporting.Fields[0], {
        Case: 'Supplier',
        Fields: [licensing.Fields[0]],
      });
      const systemId = thirdPartyLicensing ? thirdPartyLicensing.systemId : null;

      return systemId === null
        ? { type: 'SystemOfSupplier', supplierId: licensing.Fields[0] }
        : { type: 'System', systemId };
    }

    return { type: 'SystemOfSupplier', supplierId: licensing.Fields[0] };
  }

  if (licensing.Case === 'Customer') {
    if (typeof reporting === 'object' && reporting.Case === 'Producer') {
      const thirdPartyLicensing = getSystemForThirdPartyLicensing(reporting.Fields[0], {
        Case: 'Customer',
        Fields: [licensing.Fields[0]],
      });
      const systemId = thirdPartyLicensing ? thirdPartyLicensing.systemId : null;
      return systemId === null
        ? { type: 'SystemOfCustomer', customerId: licensing.Fields[0] }
        : { type: 'System', systemId };
    }

    return { type: 'SystemOfCustomer', customerId: licensing.Fields[0] };
  }

  expectNever(licensing);
}

const monthToPeriod = (month: number): Period | null => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Dec';
    default:
      return null;
  }
};

const monthToQuarter = (month: number): Period | null => {
  if (month >= 0 && month <= 2) {
    return 'Q1';
  }
  if (month >= 3 && month <= 5) {
    return 'Q2';
  }
  if (month >= 6 && month <= 8) {
    return 'Q3';
  }
  if (month >= 9 && month <= 11) {
    return 'Q4';
  }
  return null;
};

const monthToHalf = (month: number): Period | null => {
  if (month >= 0 && month <= 5) {
    return 'FirstHalfYear';
  }
  if (month >= 6 && month <= 11) {
    return 'SecondHalfYear';
  }
  return null;
};

export function getInitialPeriod(year: number, intervals: DeclarationInterval[]): Period {
  const now = new Date();

  if (intervals.includes('Monthly')) {
    if (year < now.getFullYear()) {
      return 'Dec';
    } else {
      return monthToPeriod(now.getMonth() - 1) ?? 'Year';
    }
  }

  if (intervals.includes('Quarterly')) {
    if (year < now.getFullYear()) {
      return 'Q4';
    } else {
      return monthToQuarter(now.getMonth() - 1) ?? 'Year';
    }
  }

  if (intervals.includes('BiYearly')) {
    if (year < now.getFullYear()) {
      return 'SecondHalfYear';
    } else {
      return monthToHalf(now.getMonth() - 1) ?? 'Year';
    }
  }

  return 'Year';
}

export function tryGetIdOfProducerThatHasToReport(responsibility: Responsibility) {
  if (responsibility.reporting === null) {
    return null;
  }

  if (responsibility.reporting === 'Customers') {
    return null;
  }

  if (responsibility.reporting.Case === 'Supplier') {
    return null;
  }

  if (responsibility.reporting.Case === 'Customer') {
    return null;
  }

  if (responsibility.reporting.Case === 'Producer') {
    return responsibility.reporting.Fields[0];
  }

  expectNever(responsibility.reporting);
}

export function producerReportingButNotLicensing(responsibility: Responsibility) {
  const producerId = tryGetIdOfProducerThatHasToReport(responsibility);
  if (producerId === null) {
    return null;
  }

  if (responsibility.licensing === null) {
    return null;
  }

  if (responsibility.licensing === 'Customers') {
    return null;
  }

  if (responsibility.licensing.Case === 'Supplier' || responsibility.licensing.Case === 'Customer') {
    return `${producerId}#${responsibility.licensing.Case}#${responsibility.licensing.Fields[0]}`;
  }

  if (responsibility.licensing.Case === 'Producer') {
    return null;
  }

  expectNever(responsibility.licensing);
}

export function isCustomers(responsible: Responsible): responsible is 'Customers' {
  return responsible === 'Customers';
}

export function isProducer(responsible: Responsible): responsible is { Case: 'Producer'; Fields: [string] } {
  return !isCustomers(responsible) && responsible.Case === 'Producer';
}

export function isSupplier(responsible: Responsible): responsible is { Case: 'Supplier'; Fields: [string] } {
  return !isCustomers(responsible) && responsible.Case === 'Supplier';
}

export function isCustomer(responsible: Responsible): responsible is { Case: 'Customer'; Fields: [string] } {
  return !isCustomers(responsible) && responsible.Case === 'Customer';
}
