import { getAllThirdPartyLicensings, getPlacings, Period, tryGetDeclarationGermany } from '../../../../api';

export interface ComparablePeriod {
  year: number;
  period: Period;
}

export async function tryGetDeclarationForComparison({
  tenantId,
  data,
}: {
  tenantId: number;
  data: ComparablePeriod | null;
}) {
  if (data === null) {
    return null;
  }

  const declaration = await tryGetDeclarationGermany({ tenantId, data });

  if (declaration === 'Running' || declaration.Case === 'Failed') {
    return null;
  }

  const placings = await getPlacings({ tenantId, data: data.year });

  const thirdPartyLicensings = await getAllThirdPartyLicensings({ tenantId, data });

  return { declaration: declaration.Fields[0], placings, thirdPartyLicensings };
}
