import { ContractValue, DeclarationCountry, Period } from '../../api';
import { expectNever } from '../../helpers';

export function getInitialYear() {
  const now = new Date();
  const year = now.getFullYear();
  return now.getMonth() <= 2 ? year - 1 : year;
}

export const isContractForPeriod = (contract: ContractValue, period: Period): boolean => {
  switch (period) {
    case 'InitialForecastYear':
    case 'ForecastYear':
    case 'Year':
      return true;
    case 'Q1':
    case 'Q2':
    case 'Q3':
    case 'Q4':
      return contract.interval == 'Quarterly';
    case 'Jan':
    case 'Feb':
    case 'Mar':
    case 'Apr':
    case 'May':
    case 'Jun':
    case 'Jul':
    case 'Aug':
    case 'Sep':
    case 'Oct':
    case 'Nov':
    case 'Dec':
      return contract.interval == 'Monthly';
    case 'FirstHalfYear':
    case 'SecondHalfYear':
      return contract.interval == 'BiYearly';

    default:
      expectNever(period);
  }
};

export const getPreviousPeriod = (year: number, period: Period): { year: number; period: Period } => {
  switch (period) {
    case 'Year':
      return { year: year - 1, period: period };
    case 'Q1':
      return { year: year - 1, period: 'Q4' };
    case 'Q2':
      return { year: year, period: 'Q1' };
    case 'Q3':
      return { year: year, period: 'Q2' };
    case 'Q4':
      return { year: year, period: 'Q3' };
    case 'Jan':
      return { year: year - 1, period: 'Dec' };
    case 'Feb':
      return { year: year, period: 'Jan' };
    case 'Mar':
      return { year: year, period: 'Feb' };
    case 'Apr':
      return { year: year, period: 'Mar' };
    case 'May':
      return { year: year, period: 'Apr' };
    case 'Jun':
      return { year: year, period: 'May' };
    case 'Jul':
      return { year: year, period: 'Jun' };
    case 'Aug':
      return { year: year, period: 'Jul' };
    case 'Sep':
      return { year: year, period: 'Aug' };
    case 'Oct':
      return { year: year, period: 'Sep' };
    case 'Nov':
      return { year: year, period: 'Oct' };
    case 'Dec':
      return { year: year, period: 'Nov' };
    case 'FirstHalfYear':
      return { year: year - 1, period: 'SecondHalfYear' };
    case 'SecondHalfYear':
      return { year: year, period: 'FirstHalfYear' };
    case 'InitialForecastYear':
      return { year: year - 1, period: 'InitialForecastYear' };
    case 'ForecastYear':
      return { year: year - 1, period: 'ForecastYear' };
  }
};

export function toDeclarationCountry(countryCode: string): DeclarationCountry {
  switch (countryCode) {
    case 'de':
      return 'Germany';
    case 'at':
      return 'Austria';
    case 'fr':
      return 'France';
    default:
      return 'Germany';
  }
}
