﻿import { Period, ProducerData, Responsible } from '../../../api';
import { expectNever } from '../../../helpers';
import { ContractsService } from '../../contracts/Contracts';
import { TFunction } from 'i18next';
import { SystemsService } from '../../systems/Systems';

export function getPeriodForFileName(year: number, period: Period) {
  switch (period) {
    case 'Q1':
      return year + '_Q1';
    case 'Q2':
      return year + '_Q2';
    case 'Q3':
      return year + '_Q3';
    case 'Q4':
      return year + '_Q4';
    case 'Year':
      return year;
    case 'InitialForecastYear':
      return year + '_InitialForecast';
    case 'ForecastYear':
      return year + '_Forecast';
    case 'FirstHalfYear':
      return year + '_H1';
    case 'SecondHalfYear':
      return year + '_H2';
    case 'Jan':
      return year + '_01';
    case 'Feb':
      return year + '_02';
    case 'Mar':
      return year + '_03';
    case 'Apr':
      return year + '_04';
    case 'May':
      return year + '_05';
    case 'Jun':
      return year + '_06';
    case 'Jul':
      return year + '_07';
    case 'Aug':
      return year + '_08';
    case 'Sep':
      return year + '_09';
    case 'Oct':
      return year + '_10';
    case 'Nov':
      return year + '_11';
    case 'Dec':
      return year + '_12';
  }

  expectNever(period);
}

export function getSystemNameForCsv(
  systemsService: SystemsService,
  contractsService: ContractsService,
  reporting: Responsible,
  licensing: Responsible
) {
  if (typeof licensing === 'object' && licensing.Case === 'Producer') {
    const contract = contractsService.get(licensing.Fields[0]);
    return contract != null ? systemsService.get(contract.systemId)?.name ?? '' : '';
  }

  return '';
}

export function getResponsibleTypeForCsv(
  t: TFunction,
  producers: Record<string, ProducerData>,
  responsible: Responsible | null
) {
  if (responsible === null) {
    return '';
  }

  return typeof responsible === 'string'
    ? t(`declarationExport.file.responsible_type.${responsible}`)
    : t(`declarationExport.file.responsible_type.${responsible.Case}`);
}

export function getResponsibleNameForCsv(
  producers: Record<string, ProducerData>,
  suppliers: Record<string, string>,
  responsible: Responsible | null
) {
  if (responsible === null) {
    return '';
  }

  if (responsible === 'Customers') {
    return '';
  }

  if (responsible.Case === 'Producer') {
    const producerId = responsible.Fields[0];
    const producer = producers[producerId];
    return producer?.name ?? '';
  }

  if (responsible.Case === 'Supplier') {
    const supplierId = responsible.Fields[0];
    return suppliers[supplierId] ?? '';
  }

  if (responsible.Case === 'Customer') {
    return '';
  }

  expectNever(responsible);
}

export function getResponsibleIdForCsv(producers: Record<string, ProducerData>, responsible: Responsible | null) {
  if (responsible === null) {
    return '';
  }

  if (responsible === 'Customers') {
    return '';
  }

  if (responsible.Case === 'Producer') {
    return responsible.Fields[0];
  }

  if (responsible.Case === 'Supplier') {
    return responsible.Fields[0];
  }

  if (responsible.Case === 'Customer') {
    return responsible.Fields[0];
  }

  expectNever(responsible);
}

export function getResponsibleLUCIDForCSV(producers: Record<string, ProducerData>, responsible: Responsible | null) {
  if (responsible === null) {
    return '';
  }

  if (responsible === 'Customers') {
    return '';
  }

  if (responsible.Case === 'Producer') {
    const producer = producers[responsible.Fields[0]];
    return producer?.lucidNumber ?? '';
  }

  if (responsible.Case === 'Supplier') {
    return '';
  }

  if (responsible.Case === 'Customer') {
    return '';
  }

  expectNever(responsible);
}

const weightFormat: Intl.NumberFormatOptions = {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  useGrouping: false,
};

export function getWeightForCsv(weight: number) {
  return weight.toLocaleString(undefined, weightFormat);
}
