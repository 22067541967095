import { PackagingWeights, Responsibility, ResponsibilityPeriod, Responsible } from '../../../../api';
import { from, first, IterableX, reduce } from 'ix/iterable';
import { filter, groupBy, map, memoize, orderBy, scan } from 'ix/iterable/operators';
import { serializeKey } from '../../../../serializeKey';
import { addWeights } from '../addWeights';

export type CombinedResponsibility = Responsibility & { comparison?: true };

export type ResponsibilityKind = 'reporting' | 'licensing' | 'payment';

export interface AggregatedWeights {
  forecast: boolean;
  current: PackagingWeights | null;
  comparison: PackagingWeights | null;
}

export type ReviewAggregation = {
  key: string;
  responsible: Responsible;
  months: IterableX<{ period: ResponsibilityPeriod; weights: AggregatedWeights }>;
  weights: AggregatedWeights;
};

const emptyAggregateWeights: AggregatedWeights = {
  forecast: false,
  current: null,
  comparison: null,
};

function getKeyFn(kind: ResponsibilityKind): (responsibility: Responsibility) => Responsible {
  return r => r[kind];
}

export function aggregate(
  kind: ResponsibilityKind,
  responsibilities: CombinedResponsibility[]
): IterableX<ReviewAggregation> {
  return from(responsibilities).pipe(
    groupBy(r => serializeKey(getKeyFn(kind)(r))),
    map(g => {
      const months = g.pipe(
        groupBy(r => serializeKey(r.period)),
        map(g => ({
          period: first(g)!.period,
          weights: reduce(
            g,
            (a, b) => ({
              forecast: b.comparison ? a.forecast : a.forecast || b.forecast,
              current: b.comparison ? a.current : addWeights(a.current, b.weights),
              comparison: b.comparison ? addWeights(a.comparison, b.weights) : a.comparison,
            }),
            emptyAggregateWeights
          ),
        })),
        orderBy(a => (typeof a.period === 'object' && a.period.Case === 'Month' ? a.period.Fields[0] : 13)),
        memoize()
      );

      return {
        key: g.key,
        responsible: first(g)![kind],
        months: months,
        weights: reduce(
          months,
          (a, b) => ({
            forecast: a.forecast || b.weights.forecast,
            current: addWeights(a.current, b.weights.current),
            comparison: addWeights(a.comparison, b.weights.comparison),
          }),
          emptyAggregateWeights
        ),
      };
    }),
    memoize()
  );
}
