import { TFunction } from 'i18next';
import { ResponsibilityPeriod } from '../../../../api';
import { expectNever } from '../../../../helpers';

export function formatResponsibilityPeriod(t: TFunction, period: ResponsibilityPeriod): string {
  if (typeof period === 'object' && period.Case === 'Month') {
    return t(`global:longMonths.${period.Fields[0]}`);
  }

  if (period === 'ThirdPartyLicensing') {
    return 'Berechnung von Lizenznehmern';
  }

  if (period === 'CappingOfReturns') {
    return t('reports:cappingOfReturns');
  }

  if (period === 'Unspecified') {
    return '--';
  }

  return expectNever(period as never);
}
