import { AggregationWithThirdPartyLicensing } from '../helpers';
import { Table } from 'react-bootstrap';
import { WeightsHeaderCells } from '../../../../WeightsHeaderCells';
import { useTranslation } from 'react-i18next';
import { ThirdPartyLicensingRow } from './ThirdPartyLicensingRow';
import { getEmptyWeights } from '../getEmptyWeights';

export function ThirdPartyLicensingList({ data }: { data: AggregationWithThirdPartyLicensing<string | null>[] }) {
  const { t } = useTranslation('reports');
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th className="col-2">{t('thirdPartyLicensingStep.reporting')}</th>
            <th className="col-2">{t('thirdPartyLicensingStep.licensing')}</th>
            <th className="col-2">{t('thirdPartyLicensingStep.source')}</th>
            <th className="col-2">{t('thirdPartyLicensingStep.systemProvider')}</th>
            <WeightsHeaderCells />
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="bg-light" colSpan={13}>
              &nbsp;
            </td>
          </tr>
          {data.map((row, index) => {
            return (
              <ThirdPartyLicensingRow
                key={index}
                originalWeights={row.weights ?? getEmptyWeights()}
                thirdPartyWeights={row.thirdPartyLicensing ? row.thirdPartyLicensing.weights : null}
                licensee={{ Case: row.licenseeType, Fields: [row.licensee] }}
                systemId={row.thirdPartyLicensing ? row.thirdPartyLicensing.systemId : null}
                producerId={row.producer}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
