import { getSystemForLicensing, isProducer, SystemForLicensing } from '../helpers';
import { PackagingWeights, Responsibility, Responsible } from '../../../../api';
import { from, first, IterableX, reduce, count } from 'ix/iterable';
import { filter, groupBy, map, memoize } from 'ix/iterable/operators';
import { serializeKey } from '../../../../serializeKey';
import { getEmptyWeights } from '../getEmptyWeights';
import { addWeights } from '../addWeights';

export interface ReportingAggregation {
  key: string;
  licensing: Responsible;
  system: SystemForLicensing;
  weights: PackagingWeights;
}

export interface ReportingPerSystemAggregation {
  key: string;
  system: SystemForLicensing;
  weights: PackagingWeights;
  count: number;
}

export function aggregate(
  getSystemForLicensing: (reporting: Responsible, licensing: Responsible) => SystemForLicensing,
  producerId: string,
  responsibilities: Responsibility[]
): IterableX<ReportingAggregation> {
  return from(responsibilities).pipe(
    filter(r => isProducer(r.reporting) && r.reporting.Fields[0] === producerId),
    groupBy(r => serializeKey(r.licensing)),
    map(g => {
      const f = first(g)!;
      const a: ReportingAggregation = {
        key: g.key,
        licensing: f.licensing,
        system: getSystemForLicensing({ Case: 'Producer', Fields: [producerId] }, f.licensing),
        weights: reduce(g, (a, c) => addWeights(a, c.weights), null as PackagingWeights | null) ?? getEmptyWeights(),
      };
      return a;
    }),
    memoize()
  );
}

export function aggregatePerSystem(aggregations: IterableX<ReportingAggregation>) {
  return aggregations.pipe(
    groupBy(a => serializeKey(a.system)),
    map(g => {
      const f = first(g)!;
      const a: ReportingPerSystemAggregation = {
        key: g.key,
        system: f.system,
        weights: reduce(g, (a, c) => addWeights(a, c.weights), null as PackagingWeights | null) ?? getEmptyWeights(),
        count: count(g),
      };
      return a;
    })
  );
}
