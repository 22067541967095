import { DeclarationResultGermany, getPlacings, Period, PlacingData, Responsibility, Responsible } from '../../../api';
import { getPeriodContains, notNullOrUndefined } from '../../../helpers';
import { useQueryValue } from '../../../shared/hooks/useQueryValue';
import { useTenantQuery } from '../../../shared/hooks/useTenantQuery';
import { useMemo } from 'react';
import { useProducersWithContractForPeriod } from './useContractFilterFunctions';
import { getEmptyWeights } from './getEmptyWeights';
import { isForecastedPeriod } from '../isForecastedPeriod';
function toResponsibility(period: Period, placing: PlacingData): Responsibility | null {
  return getPeriodContains(period, placing.period)
    ? {
        forecast: isForecastedPeriod(period),
        period: 'Unspecified',
        reporting: { Case: 'Producer', Fields: [placing.producerId] },
        licensing: { Case: 'Producer', Fields: [placing.producerId] },
        payment: { Case: 'Producer', Fields: [placing.producerId] },
        weights: placing.weights,
      }
    : null;
}

/**
 * Returns the relevant placings of the specified period as responsibilities.
 */
export function getResponsibilitiesFromPlacings(period: Period, placings: Record<string, PlacingData>) {
  return Object.values(placings)
    .map(placing => toResponsibility(period, placing))
    .filter(notNullOrUndefined);
}

function getResponsibilitiesOfMissingProducers(
  period: Period,
  producersWithContracts: string[],
  responsibilities: Responsibility[]
): Responsibility[] {
  return producersWithContracts
    .filter(producerId =>
      responsibilities.every(
        r => r.licensing === 'Customers' || r.licensing.Case !== 'Producer' || r.licensing.Fields[0] !== producerId
      )
    )
    .map(producerId => {
      const responsible: Responsible = {
        Case: 'Producer',
        Fields: [producerId],
      };
      return {
        period: 'Unspecified',
        reporting: responsible,
        licensing: responsible,
        payment: responsible,
        weights: getEmptyWeights(),
        forecast: isForecastedPeriod(period),
      };
    });
}

export function getExtendedResponsibilities(
  period: Period,
  placings: Record<string, PlacingData>,
  producersWithContracts: string[],
  responsibilities: Responsibility[]
) {
  const responsibilitiesFromPlacings = getResponsibilitiesFromPlacings(period, placings);

  responsibilities = [...responsibilities, ...responsibilitiesFromPlacings];
  const responsibilitiesOfMissingProducers = getResponsibilitiesOfMissingProducers(
    period,
    producersWithContracts,
    responsibilities
  );

  return [...responsibilities, ...responsibilitiesOfMissingProducers];
}

export function getDeclarationWithAdditionalResponsibilities(
  period: Period,
  placings: Record<string, PlacingData>,
  producersWithContracts: string[],
  declaration: DeclarationResultGermany
) {
  return {
    ...declaration,
    responsibilities: getExtendedResponsibilities(
      period,
      placings,
      producersWithContracts,
      declaration.responsibilities
    ),
  };
}

/**
 * Extends the declaration with data from the client like other placings.
 */
export function useExtendedDeclaration(year: number, period: Period, declaration: DeclarationResultGermany | null) {
  const placings = useQueryValue(useTenantQuery(getPlacings, year));
  const producerWithContracts = useProducersWithContractForPeriod(period);

  return useMemo(() => {
    if (declaration) {
      return getDeclarationWithAdditionalResponsibilities(period, placings, producerWithContracts, declaration);
    }

    return null;
  }, [period, declaration, placings]);
}
