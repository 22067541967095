import { PackagingWeights, Responsible } from '../../../../api';
import { SystemForLicensing } from '../helpers';
import { SystemForLicensingInfo } from '../SystemForLicensingInfo';
import { WeightsRowCells } from '../../../../WeightsRowCells';
import { ResponsibleView } from '../../ResponsibleView';

export interface Props {
  producerId: string;
  weights: PackagingWeights;
  system: SystemForLicensing;
  reporting: Responsible;
}

export function LicenseDetailRow({ producerId, weights, system, reporting }: Props) {
  return (
    <tr>
      <td className="align-middle">
        <SystemForLicensingInfo system={system} producerId={producerId} />
      </td>
      <td className="align-middle">
        <ResponsibleView responsible={reporting} />
      </td>
      <WeightsRowCells weights={weights} />
    </tr>
  );
}
