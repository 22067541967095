﻿import { DeclarationResultGermany, Responsibility, Responsible } from '../../../../api';
import { useProducers } from '../../../producers/Producers';
import { stringParam, useSearchParams } from '../../../../shared/hooks/useSearchParams';
import { Actions } from '../Actions';
import { InfoScreen } from '../../../../shared/components/InfoScreen';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteObject, useOutletContext } from 'react-router';
import { NotFoundInfo } from '../../../../shared/components/NotFoundInfo';
import { ReviewList } from './ReviewList';
import { expectNever, getPeriodShortName } from '../../../../helpers';
import { usePeriodParam, useYearParam } from '../../../../shared/hooks/useParam';
import { useTenantQuery } from '../../../../shared/hooks/useTenantQuery';
import { Fragment, useState } from 'react';
import { useThirdPartyLicensing } from '../thirdPartyLicensing/ThirdPartyLicensing';
import { aggregate, ResponsibilityKind } from './aggregate';
import { ComparablePeriod, tryGetDeclarationForComparison } from './tryGetDeclarationForComparison';
import { getComparablePeriods } from './getComparablePeriods';
import { ResponsibilityKindNavItem } from './ResponsibilityKindNavItem';
import { serializeKey } from '../../../../serializeKey';
import { filter } from 'ix/iterable/operators';
import { getExtendedResponsibilities } from '../useExtendedDeclaration';
import { useProducersWithContractForPeriod } from '../useContractFilterFunctions';
import { getResponsibilitiesWithThirdPartyLicensing } from '../getResponsibilitiesWithThirdPartyLicensing';

export const reviewRoutes: RouteObject[] = [
  { path: 'review', element: <Navigate to="./reporting" /> },
  { path: 'review/reporting', element: <ReviewStep kind="reporting" /> },
  { path: 'review/licensing', element: <ReviewStep kind="licensing" /> },
  { path: 'review/payment', element: <ReviewStep kind="payment" /> },
];

export function ReviewStep({ kind }: { kind: ResponsibilityKind }) {
  const { t } = useTranslation('reports');

  const [search, setSearch] = useSearchParams('filter', stringParam<string>(''));
  const [year, period] = [useYearParam(), usePeriodParam()];
  const declaration = useOutletContext<DeclarationResultGermany | null>();
  const producersWithContracts = useProducersWithContractForPeriod(period);

  const comparablePeriods = getComparablePeriods(year, period);

  const [comparablePeriod, setComparablePeriod] = useState<ComparablePeriod | null>(null);

  const comparisonQuery = useTenantQuery(tryGetDeclarationForComparison, comparablePeriod, false);

  const producers = useProducers();

  if (declaration === null) {
    return <NotFoundInfo />;
  }

  // Third party licensing
  const responsibilities = getResponsibilitiesWithThirdPartyLicensing(
    period,
    declaration.responsibilities,
    useThirdPartyLicensing().all
  );

  // Comparison
  let comparisonResponsibilities: Responsibility[] = [];
  if (comparisonQuery.status === 'resolved' && comparablePeriod !== null && comparisonQuery.data !== null) {
    const responsibilities = getExtendedResponsibilities(
      period,
      comparisonQuery.data.placings,
      producersWithContracts,
      comparisonQuery.data.declaration.responsibilities
    );

    comparisonResponsibilities = getResponsibilitiesWithThirdPartyLicensing(
      comparablePeriod.period,
      responsibilities,
      comparisonQuery.data.thirdPartyLicensings
    );
  }

  // Aggregations
  const aggregations = aggregate(kind, [
    ...responsibilities,
    ...comparisonResponsibilities.map(r => ({ ...r, comparison: true })),
  ]);

  // Search
  const lowerCasedSearch = search.toLocaleLowerCase();

  function stringContainsSearch(value: string | null) {
    return lowerCasedSearch === '' || (value?.toLocaleLowerCase().includes(lowerCasedSearch) ?? false);
  }

  function containsSearch(value: Responsible | null) {
    if (value === null) {
      return false;
    }

    if (value === 'Customers') {
      return false;
    }

    if (value.Case === 'Producer') {
      if (stringContainsSearch(value.Fields[0])) {
        return true;
      }

      const producer = producers.accessible()[value.Fields[0]];
      if (producer) {
        return stringContainsSearch(producer.name) || stringContainsSearch(producer.lucidNumber);
      }

      return false;
    }

    if (value.Case === 'Supplier') {
      return stringContainsSearch(value.Fields[0]);
    }

    if (value.Case === 'Customer') {
      return stringContainsSearch(value.Fields[0]);
    }

    expectNever(value);
  }

  const filtered = aggregations.pipe(
    filter(a => {
      return lowerCasedSearch === '' || containsSearch(a.responsible);
    })
  );

  return (
    <div>
      <Actions year={year} period={period} declaration={declaration} />

      <div className="bg-light rounded mb-4">
        <InfoScreen icon="eye" color="primary" title={t('reviewStep.title')}>
          <p>{t('reviewStep.subtitle.de')}</p>
        </InfoScreen>
      </div>
      <ul className="nav nav-tabs">
        <li className="nav-item pe-2 pb-2 col-4">
          <input
            placeholder={t('global:search')}
            type="search"
            className="form-control"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
        </li>
        <ResponsibilityKindNavItem kind="reporting" />
        <ResponsibilityKindNavItem kind="licensing" />
        <ResponsibilityKindNavItem kind="payment" />
        <li className="nav-item ms-auto">
          <span className="me-2 text-secondary">{t('reviewStep.compareWith')}</span>
          <div className="btn-group" role="group">
            <>
              <input
                type="radio"
                className="btn-check"
                id="comparable-period-null"
                autoComplete="off"
                onChange={() => setComparablePeriod(null)}
                checked={comparablePeriod === null}
              />
              <label className="btn btn-outline-secondary" htmlFor="comparable-period-null">
                None
              </label>
            </>
            {comparablePeriods.map(c => (
              <Fragment key={serializeKey(c)}>
                <input
                  type="radio"
                  className="btn-check"
                  id={`comparable-period-${serializeKey(c)}`}
                  autoComplete="off"
                  onChange={() => setComparablePeriod(c)}
                  checked={c.year === comparablePeriod?.year && c.period === comparablePeriod?.period}
                />
                <label className="btn btn-outline-secondary" htmlFor={`comparable-period-${serializeKey(c)}`}>
                  {getPeriodShortName(c.period)} {c.year}
                </label>
              </Fragment>
            ))}
          </div>
        </li>
      </ul>
      <ReviewList data={filtered} />
    </div>
  );
}
