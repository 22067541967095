import { weightFormat } from '../../helpers';
import { Percentage } from './Percentage';

export function ReviewWeightsCell<T extends { [P in keyof T]: number }>(props: {
  weightKey: keyof T;
  weights: T | null;
  comparison: T | null;
}) {
  return (
    <td className="align-start text-end text-nowrap">
      <span className="d-block">
        {props.weights ? <>{weightFormat.format(props.weights[props.weightKey])} kg</> : '--'}
      </span>
      {props.comparison && (
        <div className="mt-2 text-muted opacity-50" style={{ fontSize: '13px' }}>
          <span>{weightFormat.format(props.comparison[props.weightKey])} kg</span>
          {props.weights && (
            <>
              {' '}
              <span className="text-primary">
                <Percentage a={props.weights[props.weightKey]} b={props.comparison[props.weightKey]} />
              </span>
            </>
          )}
        </div>
      )}
    </td>
  );
}
