function serializeToKeyReplacer(key: string, value: any) {
  if (value === null) {
    return null;
  }

  if (typeof value === 'object') {
    const keys = Object.keys(value);
    keys.sort();
    const data: any = {};
    for (const key of keys) {
      data[key] = value[key];
    }
    return data;
  }

  return value;
}

export function serializeKey(value: any) {
  return JSON.stringify(value, serializeToKeyReplacer);
}
