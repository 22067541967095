import { Period } from '../../../../api';
import { ComparablePeriod } from './tryGetDeclarationForComparison';
import { expectNever } from '../../../../helpers';

export function getComparablePeriods(year: number, period: Period): ComparablePeriod[] {
  switch (period) {
    case 'Year':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'InitialForecastYear' },
      ];
    case 'Q1':
      return [
        { year: year - 1, period: period },
        { year: year - 1, period: 'Q4' },
      ];
    case 'Q2':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Q1' },
      ];
    case 'Q3':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Q2' },
      ];
    case 'Q4':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Q3' },
      ];
    case 'Jan':
      return [
        { year: year - 1, period: period },
        { year: year - 1, period: 'Dec' },
      ];
    case 'Feb':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Jan' },
      ];
    case 'Mar':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Feb' },
      ];
    case 'Apr':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Mar' },
      ];
    case 'May':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Apr' },
      ];
    case 'Jun':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'May' },
      ];
    case 'Jul':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Jun' },
      ];
    case 'Aug':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Jul' },
      ];
    case 'Sep':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Aug' },
      ];
    case 'Oct':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Sep' },
      ];
    case 'Nov':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Oct' },
      ];
    case 'Dec':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'Nov' },
      ];
    case 'FirstHalfYear':
      return [
        { year: year - 1, period: period },
        { year: year - 1, period: 'SecondHalfYear' },
      ];
    case 'SecondHalfYear':
      return [
        { year: year - 1, period: period },
        { year: year, period: 'FirstHalfYear' },
      ];
    case 'InitialForecastYear':
      return [
        { year: year - 1, period: period },
        { year: year - 1, period: 'Year' },
        { year: year, period: 'Year' },
      ];
    case 'ForecastYear':
      return [{ year: year, period: 'Year' }];
  }

  expectNever(period);
}
