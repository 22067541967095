import { AggregatedPackagingWeightsAustria } from '../../../../api';
import { useTranslation } from 'react-i18next';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { IterableX, toArray } from 'ix/iterable';
import { ReviewAggregation } from './aggregate';
import { selectAndNumericCompare } from '../../selectAndNumericCompare';
import { useCompareResponsible } from '../../de/CompareResponsible';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { SortableWeightsHeaderCells } from '../SortableWeightsHeaderCells';
import { PackagingKind } from './ResponsibilityKindNavItem';
import { ReviewListRow } from './ReviewListRow';

export interface Props {
  data: IterableX<ReviewAggregation>;
  kind: PackagingKind;
}

export function ReviewList({ kind, data }: Props) {
  const { t } = useTranslation('reports');

  const compareResponsible = useCompareResponsible();

  function compareKey(a: ReviewAggregation, b: ReviewAggregation) {
    return compareResponsible(a.responsible, b.responsible);
  }

  const sorting = useSorting<ReviewAggregation, 'key' | keyof AggregatedPackagingWeightsAustria>(
    toArray(data),
    {
      key: compareKey,
      glassHouseHold: selectAndNumericCompare(a => a.weights.current?.glassHouseHold),
      fiberHouseHold: selectAndNumericCompare(a => a.weights.current?.fiberHouseHold),
      ferrousMetalHouseHold: selectAndNumericCompare(a => a.weights.current?.ferrousMetalHouseHold),
      aluminumHouseHold: selectAndNumericCompare(a => a.weights.current?.aluminumHouseHold),
      plasticHouseHold: selectAndNumericCompare(a => a.weights.current?.plasticHouseHold),
      beverageCartonHouseHold: selectAndNumericCompare(a => a.weights.current?.beverageCartonHouseHold),
      compositeHouseHold: selectAndNumericCompare(a => a.weights.current?.compositeHouseHold),
      ceramicHouseHold: selectAndNumericCompare(a => a.weights.current?.ceramicHouseHold),
      woodHouseHold: selectAndNumericCompare(a => a.weights.current?.woodHouseHold),
      textileHouseHold: selectAndNumericCompare(a => a.weights.current?.textileHouseHold),
      organicHouseHold: selectAndNumericCompare(a => a.weights.current?.organicHouseHold),
      fiberCommercial: selectAndNumericCompare(a => a.weights.current?.fiberCommercial),
      ferrousMetalCommercial: selectAndNumericCompare(a => a.weights.current?.ferrousMetalCommercial),
      aluminumCommercial: selectAndNumericCompare(a => a.weights.current?.aluminumCommercial),
      foilsCommercial: selectAndNumericCompare(a => a.weights.current?.foilsCommercial),
      hollowBodiesCommercial: selectAndNumericCompare(a => a.weights.current?.hollowBodiesCommercial),
      epsCommercial: selectAndNumericCompare(a => a.weights.current?.epsCommercial),
      compositeCommercial: selectAndNumericCompare(a => a.weights.current?.compositeCommercial),
      ceramicCommercial: selectAndNumericCompare(a => a.weights.current?.ceramicCommercial),
      woodCommercial: selectAndNumericCompare(a => a.weights.current?.woodCommercial),
      textileCommercial: selectAndNumericCompare(a => a.weights.current?.textileCommercial),
      organicCommercial: selectAndNumericCompare(a => a.weights.current?.organicCommercial),
    },
    { key: 'key', direction: 'asc' }
  );

  return (
    <div>
      <table className="table table-striped table-hover">
        <thead className="">
          <tr>
            <th style={{ width: '1%' }}></th>
            <SortableHeaderCell sorting={sorting} sortKey="key">
              {t('reviewStep.company')}
            </SortableHeaderCell>
            <th></th>
            <SortableWeightsHeaderCells
              household={kind === 'household'}
              commercial={kind === 'commercial'}
              sorting={sorting}
            />
          </tr>
        </thead>
        <tbody>
          {sorting.sorted.map(a => (
            <ReviewListRow key={a.key} kind={kind} aggregation={a} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
