﻿import { PackagingWeights, Responsible } from '../../../../api';
import { SystemForLicensing } from '../helpers';
import { ReportDetailPerLicenserRow } from './ReportDetailPerLicenserRow';
import { useSorting } from '../../../../shared/hooks/useSorting';
import { SortableHeaderCell } from '../../../../shared/components/SortableHeaderCell';
import { useCompareResponsible } from '../CompareResponsible';
import { selectAndNumericCompare } from '../../selectAndNumericCompare';
import { selectAndLocaleCompare } from '../../selectAndLocaleCompare';
import { PackagingWeightsKeys } from '../PackagingWeightsKeys';
import { useTranslation } from 'react-i18next';
import { ReportingAggregation } from './aggregate';
import { IterableX, toArray } from 'ix/iterable';
import { useGetSystemNameFn } from '../UseGetSystemNameFn';
import { getEmptyWeights } from '../getEmptyWeights';
import { SortableWeightsHeaderCells } from '../SortableWeightsHeaderCells';

export interface Props {
  producerId: string;
  aggregations: IterableX<ReportingAggregation>;
}

export function ReportDetailPerLicenser({ aggregations, producerId }: Props) {
  const { t } = useTranslation('reports');

  const getSystemName = useGetSystemNameFn();
  const compareResponsible = useCompareResponsible();

  const sorting = useSorting<ReportingAggregation, 'licensing' | 'system' | PackagingWeightsKeys>(
    toArray(aggregations),
    {
      licensing: (a, b) => compareResponsible(a.licensing, b.licensing),
      system: selectAndLocaleCompare<ReportingAggregation>(r => getSystemName(r.system)),
      glass: selectAndNumericCompare<ReportingAggregation>(r => r.weights.glass),
      fiber: selectAndNumericCompare<ReportingAggregation>(r => r.weights.fiber),
      ferrousMetal: selectAndNumericCompare<ReportingAggregation>(r => r.weights.ferrousMetal),
      aluminum: selectAndNumericCompare<ReportingAggregation>(r => r.weights.aluminum),
      plastic: selectAndNumericCompare<ReportingAggregation>(r => r.weights.plastic),
      beverageCarton: selectAndNumericCompare<ReportingAggregation>(r => r.weights.beverageCarton),
      otherComposite: selectAndNumericCompare<ReportingAggregation>(r => r.weights.otherComposite),
      other: selectAndNumericCompare<ReportingAggregation>(r => r.weights.other),
    },
    { key: 'licensing', direction: 'asc' }
  );

  const emptyResponsible: Responsible = {
    Case: 'Producer',
    Fields: [producerId],
  };

  const emptySystem: SystemForLicensing = {
    type: 'SystemOfProducer',
    producerId: producerId,
  };

  const emptyWeights: PackagingWeights = getEmptyWeights();

  return (
    <table className="table table-hover  table-striped">
      <thead className="sticky-top bg-white">
        <tr>
          <SortableHeaderCell sorting={sorting} sortKey="licensing">
            {t('reportDetailPerLicenser.licensedBy')}
          </SortableHeaderCell>
          <SortableHeaderCell sorting={sorting} sortKey="system">
            {t('reportDetailPerLicenser.systemProvider')}
          </SortableHeaderCell>
          <SortableWeightsHeaderCells sorting={sorting} />
        </tr>
      </thead>
      <tbody>
        {sorting.sorted.length > 0 ? (
          sorting.sorted.map(a => (
            <ReportDetailPerLicenserRow
              key={a.key}
              producerId={producerId}
              licensing={a.licensing}
              system={a.system}
              weights={a.weights}
            />
          ))
        ) : (
          <ReportDetailPerLicenserRow
            producerId={producerId}
            licensing={emptyResponsible}
            system={emptySystem}
            weights={emptyWeights}
          />
        )}
      </tbody>
    </table>
  );
}
